import {
  RangeFacet,
  TextFacet,
} from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { ReferenceStock } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStock';
import { TFunction } from 'i18next';
import moment from 'moment/moment';
import { IAM, TIRE } from 'domains/catalog/Catalog.types';
import { ALL, SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';
import { ERROR, SearchData } from 'utils';

export const TEXT_FILTER_TYPE = 'TEXT';
export const RANGE_FILTER_TYPE = 'RANGE';

export interface FiltersRange {
  min: number;
  max: number;
}

interface Facet {
  active: boolean;
  order?: number;
}

export type FilterType = typeof IAM | typeof TIRE | 'UniversalProducts';

export interface TextFacetLocal extends Facet, TextFacet {}

export interface RangeFacetLocal extends Facet, RangeFacet {}

export interface ReferenceFilterTypes {
  textFilters: TextFacetLocal[] | undefined;
  rangeFilters: RangeFacetLocal[] | undefined;
}

export interface Filters {
  textFilters: Map<string, string[]>;
  rangeFilters: Map<string, FiltersRange>;
}

export const FILTER_BRAND = 'supplierLabel';
export const FILTER_SEASON = 'seasonLabel';
export const FILTER_DELIVERY = 'delivery';
export const FILTER_PRICES = 'prices';
export const FILTER_POSITION = 'position';
export const FILTER_FUEL_EFFICIENCY = 'fuelEfficiencyLabel';
export const FILTER_FEATURES = 'featuresLabel';
export const FILTER_PRODUCT_RANGE = 'productRangeLabel';
export const FILTER_WET_GRIP = 'wetGripLabel';
export const FILTER_NOISE_LEVEL = 'exteriorSoundLabel';

export const SELF_SEAL_LABEL = 'self_seal';
export const PMSF_LABEL = 'pmsf';
export const EXTRA_LOAD_LABEL = 'extra_load';
export const RUN_FLAT_LABEL = 'run_flat';
export const PREMIUM_BRAND_LABEL = 'premium';
export const MEDIUM_BRAND_LABEL = 'medium';
export const ENTRY_BRAND_LABEL = 'entry';

export const POSITIVE_FEATURE_VALUES = ['Y', 'Yes', 'OUI'];

const getPosition = (index: number, t: TFunction): string | undefined => {
  switch (index) {
    case 1:
      return t('catalog.iam.spare_parts.filter.position.rear', 'Rear');
    case 2:
      return t('catalog.iam.spare_parts.filter.position.front', 'Front');
    case 3:
      return t('catalog.iam.spare_parts.filter.position.front_rear', 'Front Rear');
    case 4:
      return t('catalog.iam.spare_parts.filter.position.right', 'Right');
    case 5:
      return t('catalog.iam.spare_parts.filter.position.right_rear', 'Right Rear');
    case 6:
      return t('catalog.iam.spare_parts.filter.position.right_front', 'Right Front');
    case 7:
      return t('catalog.iam.spare_parts.filter.position.right_front_rear', 'Right Front Rear');
    case 8:
      return t('catalog.iam.spare_parts.filter.position.left', 'Left');
    case 9:
      return t('catalog.iam.spare_parts.filter.position.left_rear', 'Left Rear');
    case 10:
      return t('catalog.iam.spare_parts.filter.position.left_front', 'Left Front');
    case 11:
      return t('catalog.iam.spare_parts.filter.position.left_front_rear', 'Left Front Rear');
    case 12:
      return t('catalog.iam.spare_parts.filter.position.left_right', 'Left Right');
    case 13:
      return t('catalog.iam.spare_parts.filter.position.left_right_rear', 'Left Right Rear');
    case 14:
      return t('catalog.iam.spare_parts.filter.position.left_right_front', 'Left Right Front');
    case 15:
      return t('catalog.iam.spare_parts.filter.position.left_right_front_rear', 'Left Right Front Rear');
    case 16:
      return t('catalog.iam.spare_parts.filter.position.lower', 'Lower');
    case 17:
      return t('catalog.iam.spare_parts.filter.position.lower_rear', 'Lower Rear');
    case 18:
      return t('catalog.iam.spare_parts.filter.position.lower_front', 'Lower Front');
    case 20:
      return t('catalog.iam.spare_parts.filter.position.upper', 'Upper');
    case 22:
      return t('catalog.iam.spare_parts.filter.position.right_lower_front', 'Right Lower Front');
    case 24:
      return t('catalog.iam.spare_parts.filter.position.lower_left', 'Lower Left');
    case 25:
      return t('catalog.iam.spare_parts.filter.position.left_lower_rear', 'Left Lower Rear');
    case 26:
      return t('catalog.iam.spare_parts.filter.position.left_lower_front', 'Left Lower Front');
    case 33:
      return t('catalog.iam.spare_parts.filter.position.upper_rear', 'Upper Rear');
    case 34:
      return t('catalog.iam.spare_parts.filter.position.upper_front', 'Upper Front');
    case 36:
      return t('catalog.iam.spare_parts.filter.position.upper_right', 'Upper Right');
    case 38:
      return t('catalog.iam.spare_parts.filter.position.right_upper_front', 'Right Upper Front');
    case 40:
      return t('catalog.iam.spare_parts.filter.position.upper_left', 'Upper Left');
    case 42:
      return t('catalog.iam.spare_parts.filter.position.left_upper_front', 'Left Upper Front');
    default:
      return undefined;
  }
};

export const getDeliveryFilterData = (
  delivery: string,
  t: TFunction,
): { label: string; stockTypes: string[] } | undefined => {
  switch (delivery) {
    case 'r1':
      return { label: t('backoffice.promotion.target_audience.r1', 'R1'), stockTypes: ['LOCAL'] };
    case 'reg_cen_wh':
      return {
        label: t('catalog.reference.stock.regional_central', 'Regional/central warehouse'),
        stockTypes: ['REGION', 'CENTRAL'],
      };
    case 'restock':
      return { label: t('common.restocking', 'Restocking'), stockTypes: ['DELAYED'] };
    case 'ww':
      return { label: t('catalog.reference.stock.worldwide_short', 'Worldwide'), stockTypes: ['WW'] };
    case 'h24':
      return { label: t('catalog.reference.stock.h24', '<24h'), stockTypes: ['LOCAL', 'EXADIS', 'MANUFACTURER'] };
    case 'h2448':
      return {
        label: t('catalog.reference.stock.h2448', '24-48h'),
        stockTypes: ['REGION', 'CENTRAL', 'EXADIS', 'MANUFACTURER'],
      };
    case 'h48p':
      return {
        label: t('catalog.reference.stock.h48p', '+48h'),
        stockTypes: ['WW', 'EXADIS', 'MANUFACTURER', 'DELAYED'],
      };
    case 'h48':
      return { label: t('catalog.reference.stock.h48', '48h'), stockTypes: ['EXADIS', 'MANUFACTURER', 'MARKETPLACE'] };
    case 'out_of_stock':
      return {
        label: t('catalog.filter.out_of_stock', 'Out of stock'),
        stockTypes: [],
      };
    default:
      return undefined;
  }
};

export const getStockFilterOptions = (
  page: 'TIRES' | 'UNIVERSAL_PRODUCTS' | 'IAM',
  stocksLoaded: boolean,
): TextFacetLocal => {
  const r1 = {
    label: 'r1',
    numberOfItems: 1,
  };
  const regCentWh = {
    label: 'reg_cen_wh',
    numberOfItems: 1,
  };
  const restocking = {
    label: 'restock',
    numberOfItems: 1,
  };
  const worldwide = {
    label: 'ww',
    numberOfItems: 1,
  };
  const h24 = {
    label: 'h24',
    numberOfItems: 1,
  };
  const h24_48 = {
    label: 'h2448',
    numberOfItems: 1,
  };
  const h48p = {
    label: 'h48p',
    numberOfItems: 1,
  };
  const h48 = {
    label: 'h48',
    numberOfItems: 1,
  };
  const outOfStock = {
    label: 'out_of_stock',
    numberOfItems: 1,
  };

  const getItems = () => {
    switch (page) {
      case 'UNIVERSAL_PRODUCTS':
        return [r1, regCentWh, restocking, worldwide];
      case 'IAM':
        return [h24, h24_48, h48p, outOfStock];
      case 'TIRES':
        return [r1, h48, h48p];
      default:
        return [];
    }
  };

  return {
    id: 'delivery',
    type: 'TEXT',
    items: getItems(),
    active: stocksLoaded,
  };
};

export const isFilteredStocks = (
  stock: SearchData<ReferenceStock> | undefined,
  filter: Filters,
  t: TFunction,
): boolean => {
  const stockSearchStatus = stock?.searchStatus;
  if (stockSearchStatus === ERROR || stockSearchStatus === undefined) {
    return false;
  }
  const warehouses = stock?.data?.warehouses ?? [];
  const filteredOptions = filter.textFilters.get('delivery') ?? [];
  const filteredStockTypes = filteredOptions.map((i) => getDeliveryFilterData(i, t)?.stockTypes).flat();
  if (warehouses.length === 0 && filteredStockTypes.length === 0) {
    return true;
  }
  return warehouses.some((wh) => {
    const currentStockType = wh.type;
    const isFiltered = filteredStockTypes.includes(currentStockType);
    if (isFiltered && ['EXADIS', 'MANUFACTURER'].includes(currentStockType)) {
      const hoursUntilDelivery = moment(wh?.expectedDeliveryDate).diff(moment(), 'h');
      return (
        (filteredOptions.includes('h24') && hoursUntilDelivery <= 24) ||
        ((filteredOptions.includes('h24_48') || filteredOptions.includes('h48')) && hoursUntilDelivery <= 48) ||
        (filteredOptions.includes('h48p') && hoursUntilDelivery > 48)
      );
    } else {
      return isFiltered;
    }
  });
};

export const getFiltersMap = (t: TFunction): Map<string, { label: string; ids: string[] }> => {
  const facetMap = new Map();
  facetMap.set(FILTER_BRAND, {
    label: t('catalog.iam.spare_parts.filter.brands', 'Brands'),
    ids: [],
  });
  facetMap.set(FILTER_SEASON, {
    label: t('catalog.iam.spare_parts.filter.season', 'Seasons'),
    ids: [],
  });
  facetMap.set(FILTER_DELIVERY, {
    label: t('my_orders.pdf.delivery', 'Delivery'),
    ids: [],
  });
  facetMap.set(FILTER_PRICES, {
    label: t('catalog.filter.prices', 'Prices'),
    ids: [],
  });
  facetMap.set('batteryCapacity', {
    label: t('catalog.iam.spare_parts.filter.batteryCapacity', 'Battery capacity'),
    ids: ['68'],
  });
  facetMap.set('belts', {
    label: t('catalog.iam.spare_parts.filter.belts', 'Belts'),
    ids: ['934'],
  });
  facetMap.set('brakeDiscType', {
    label: t('catalog.iam.spare_parts.filter.brakeDiscType', 'Break disc type'),
    ids: ['232'],
  });
  facetMap.set('diameter', {
    label: t('catalog.iam.spare_parts.filter.diameter', 'Diameter'),
    ids: ['200'],
  });
  facetMap.set('drivenUnit', {
    label: t('catalog.iam.spare_parts.filter.drivenUnit', 'Driven unit'),
    ids: ['105'],
  });
  facetMap.set('engineCode', {
    label: t('catalog.iam.spare_parts.filter.engineCode', 'Engine code'),
    ids: ['33', '14', '1245'],
  });
  facetMap.set('euroStandard', {
    label: t('catalog.iam.spare_parts.filter.euroStandard', 'Euro standard'),
    ids: ['519'],
  });
  facetMap.set('exceedsSpecifications', {
    label: t('catalog.iam.spare_parts.filter.exceedsSpecifications', 'Exceeds specifications'),
    ids: ['1161', '1160'],
  });
  facetMap.set('filterType', {
    label: t('catalog.iam.spare_parts.filter.filterType', 'Filter type'),
    ids: ['826'],
  });
  facetMap.set('firstRegistrationDate', {
    label: t('catalog.iam.spare_parts.filter.firstRegistrationDate', 'First registration date'),
    ids: ['1514', '1513', '1321', '1320', '20', '21'],
  });
  facetMap.set('gearBoxCode', {
    label: t('catalog.iam.spare_parts.filter.gearBoxCode', 'Gear box code'),
    ids: ['38'],
  });
  facetMap.set('gearBoxSpeed', {
    label: t('catalog.iam.spare_parts.filter.gearBoxSpeed', 'Gear box speed'),
    ids: ['831'],
  });
  facetMap.set('gearBoxType', {
    label: t('catalog.iam.spare_parts.filter.gearBoxType', 'Gear box type'),
    ids: ['40'],
  });
  facetMap.set('height', {
    label: t('catalog.iam.spare_parts.filter.height', 'Height'),
    ids: ['339', '209'],
  });
  facetMap.set('length', {
    label: t('catalog.iam.spare_parts.filter.length', 'Length'),
    ids: ['203'],
  });
  facetMap.set('manufacturerRestriction', {
    label: t('catalog.iam.spare_parts.filter.manufacturerRestriction', 'Manufacturer restriction'),
    ids: ['1440', '122', '3524', '649'],
  });
  facetMap.set('numberBelts', {
    label: t('catalog.iam.spare_parts.filter.numberBelts', 'Number belts'),
    ids: ['1360'],
  });
  facetMap.set('material', {
    label: t('catalog.iam.spare_parts.filter.material', 'Material'),
    ids: ['468'],
  });
  facetMap.set('numberOfDoors', {
    label: t('catalog.iam.spare_parts.filter.numberOfDoors', 'Number of doors'),
    ids: ['65'],
  });
  facetMap.set('oilType', {
    label: t('catalog.iam.spare_parts.filter.oilType', 'Oil type'),
    ids: ['1136'],
  });
  facetMap.set('outerDiameter', {
    label: t('catalog.iam.spare_parts.filter.outerDiameter', 'Outer diameter'),
    ids: ['497'],
  });
  facetMap.set(FILTER_POSITION, {
    label: t('catalog.iam.spare_parts.filter.position', 'Position'),
    ids: ['P100'],
  });
  facetMap.set('propulsion', {
    label: t('catalog.iam.spare_parts.filter.propulsion', 'Propulsion'),
    ids: ['112'],
  });
  facetMap.set('spannerSize', {
    label: t('catalog.iam.spare_parts.filter.spannerSize', 'Spanner size'),
    ids: ['491'],
  });
  facetMap.set('sparkPlug', {
    label: t('catalog.iam.spare_parts.filter.sparkPlug', 'Spark plug'),
    ids: ['757'],
  });
  facetMap.set('specification', {
    label: t('catalog.iam.spare_parts.filter.specification', 'Specification'),
    ids: ['1073'],
  });
  facetMap.set('thickness', {
    label: t('catalog.iam.spare_parts.filter.thickness', 'Thickness'),
    ids: ['124'],
  });
  facetMap.set('threadSize', {
    label: t('catalog.iam.spare_parts.filter.threadSize', 'Thread size'),
    ids: ['289'],
  });
  facetMap.set('viscosity', {
    label: t('catalog.iam.spare_parts.filter.viscosity', 'Viscosity'),
    ids: ['2467', '1054'],
  });
  facetMap.set('voltage', {
    label: t('catalog.iam.spare_parts.filter.voltage', 'Voltage'),
    ids: ['6'],
  });
  facetMap.set('weight', {
    label: t('catalog.iam.spare_parts.filter.weight', 'Weight'),
    ids: ['212'],
  });
  facetMap.set('wheelBase', {
    label: t('catalog.iam.spare_parts.filter.wheelBase', 'Wheel base'),
    ids: ['606'],
  });
  facetMap.set('width', {
    label: t('catalog.iam.spare_parts.filter.width', 'Width'),
    ids: ['206'],
  });
  facetMap.set(FILTER_FUEL_EFFICIENCY, {
    label: t('catalog.tires.filter.fuel_efficiency', 'Fuel efficiency'),
    ids: [],
  });
  facetMap.set(FILTER_FEATURES, {
    label: t('catalog.tires.filter.features', 'Features'),
    ids: [],
  });
  facetMap.set(FILTER_PRODUCT_RANGE, {
    label: t('catalog.tires.filter.product_range', 'Product range'),
    ids: [],
  });
  facetMap.set(FILTER_WET_GRIP, {
    label: t('catalog.tires.filter.wet_grip', 'Wet grip'),
    ids: [],
  });
  facetMap.set(FILTER_NOISE_LEVEL, {
    label: t('catalog.tires.filter.noise_level', 'Noise level'),
    ids: [],
  });

  return facetMap;
};

export const getOEMFiltersMap = (t: TFunction): Map<string, { label: string; ids: string[] }> => {
  const facetMap = new Map();
  facetMap.set('material', {
    label: t('catalog.oem.spare_parts.filter.material', 'Material'),
    ids: ['7'],
  });
  facetMap.set('length', {
    label: t('catalog.oem.spare_parts.filter.length', 'Length'),
    ids: ['29'],
  });
  facetMap.set('brakeDiscType', {
    label: t('catalog.oem.spare_parts.filter.brakeDiscType', 'Brake disc type'),
    ids: ['34'],
  });
  facetMap.set('thickness', {
    label: t('catalog.oem.spare_parts.filter.thickness', 'Thickness'),
    ids: ['96'],
  });
  facetMap.set('diameter', {
    label: t('catalog.oem.spare_parts.filter.diameter', 'Diameter'),
    ids: ['99'],
  });
  facetMap.set('outerDiameter', {
    label: t('catalog.oem.spare_parts.filter.outerDiameter', 'Outer diameter'),
    ids: ['99'],
  });
  facetMap.set('threadSize', {
    label: t('catalog.oem.spare_parts.filter.threadSize', 'Thread size'),
    ids: ['109'],
  });
  facetMap.set('drivenUnit', {
    label: t('catalog.oem.spare_parts.filter.drivenUnit', 'Driven unit'),
    ids: ['128'],
  });
  facetMap.set('filterType', {
    label: t('catalog.oem.spare_parts.filter.filterType', 'Filter type'),
    ids: ['163'],
  });
  facetMap.set('spannerSize', {
    label: t('catalog.oem.spare_parts.filter.spannerSize', 'Spanner size'),
    ids: ['846'],
  });
  facetMap.set('position', {
    label: t('catalog.iam.spare_parts.filter.position', 'Position'),
    ids: ['P100'],
  });

  return facetMap;
};

export function isValidPosition(position: string | undefined, t: TFunction): boolean {
  if (!position) return false;
  return !!getPosition(Number(position), t);
}

export function positionHasPicture(position: string | undefined, t: TFunction): boolean {
  if (!position || position === '16' || position === '20') return false;
  return !!getPosition(Number(position), t);
}

export const getPositionLabel = (position: string, t: TFunction): string | undefined => {
  return getPosition(Number(position), t);
};

export const getFilterLabel = (label: string, t: TFunction): string | undefined => {
  switch (label) {
    case WINTER: {
      return t('catalog.tires.winter', 'WINTER');
    }
    case SUMMER_WINTER: {
      return t('catalog.tires.all_seasons', 'ALL SEASONS');
    }
    case SUMMER: {
      return t('catalog.tires.summer', 'SUMMER');
    }
    case ALL: {
      return t('catalog.all', 'ALL');
    }
    default: {
      return label;
    }
  }
};

export const getFilterFuelConsumptionLabel = (label: string, t: TFunction): string | undefined => {
  switch (label) {
    case '': {
      return t('catalog.tires.filter.fuel_efficiency_unknown', 'Unknown');
    }
    default: {
      return label;
    }
  }
};

export const getFilterNoiseLevelLabel = (label: string, t: TFunction): string | undefined => {
  return t('catalog.tires.filter.noise_level.volume', '{{volume}} DB', { volume: label });
};

export const getFilterFeaturesLabel = (label: string, t: TFunction): string | undefined => {
  switch (label) {
    case SELF_SEAL_LABEL: {
      return t('catalog.tires.filter.self_seal', 'Self seal');
    }
    case PMSF_LABEL: {
      return t('catalog.tires.filter.pmsf', 'Pmsf');
    }
    case EXTRA_LOAD_LABEL: {
      return t('catalog.tires.filter.extra_load', 'Extra load');
    }
    case RUN_FLAT_LABEL: {
      return t('catalog.tires.filter.run_flat', 'Run flat');
    }
    default: {
      return label;
    }
  }
};

export const getFilterProductRangeLabel = (label: string, t: TFunction): string | undefined => {
  switch (label) {
    case PREMIUM_BRAND_LABEL: {
      return t('catalog.tires.filter.premium', 'Premium');
    }
    case MEDIUM_BRAND_LABEL: {
      return t('catalog.tires.filter.medium', 'Medium');
    }
    case ENTRY_BRAND_LABEL: {
      return t('catalog.tires.filter.entry', 'Entry');
    }
    default: {
      return label;
    }
  }
};

export const isInRange = (
  items: (string | undefined)[],
  range:
    | {
        min: number;
        max: number;
      }
    | undefined,
): boolean => {
  if (!range) return false;
  return items.some((i) => {
    const refValueNum = () => {
      const num = i?.match(/(-?[\d,]+)([a-z%]*)/);
      if (num) {
        return Number(num[1].replace(',', '.'));
      }
      return Number(i ?? 0);
    };
    return !(refValueNum() >= range.min && refValueNum() <= range.max);
  });
};

export const getFilterItemDisplayOrder = (facet: TextFacetLocal | RangeFacetLocal): number => {
  switch (facet.id) {
    case FILTER_BRAND:
      return 1;
    case FILTER_DELIVERY:
      return 2;
    case FILTER_POSITION:
      return 3;
    default:
      return 100;
  }
};

export const getTireFilterItemDisplayOrder = (facet: TextFacetLocal | RangeFacetLocal): number => {
  switch (facet.id) {
    case FILTER_BRAND:
      return 1;
    case FILTER_SEASON:
      return 2;
    case FILTER_DELIVERY:
      return 3;
    case FILTER_PRICES:
      return 4;
    case FILTER_PRODUCT_RANGE:
      return 5;
    case FILTER_FUEL_EFFICIENCY:
      return 6;
    case FILTER_NOISE_LEVEL:
      return 7;
    case FILTER_WET_GRIP:
      return 8;
    case FILTER_FEATURES:
      return 9;
    default:
      return 100;
  }
};

export function sortFacets(a: TextFacetLocal | RangeFacetLocal, b: TextFacetLocal | RangeFacetLocal): number {
  if (!a.order && !b.order) {
    return 0;
  } else if (!a.order) {
    return 1;
  } else if (!b.order) {
    return -1;
  }
  return a.order - b.order;
}

const productRangeOptionsOrder = [PREMIUM_BRAND_LABEL, MEDIUM_BRAND_LABEL, ENTRY_BRAND_LABEL];

export const getTextFacets = (textFilters: TextFacetLocal[], requiredMin: number): TextFacetLocal[] => {
  return [...textFilters].filter((f) => f.items.length > requiredMin).map((facet) => getFacetOptionsToDisplay(facet));
};

export const getFacetOptionsToDisplay = (facet: TextFacetLocal): TextFacetLocal => {
  switch (facet.id) {
    case FILTER_DELIVERY: {
      return {
        ...facet,
        items: [...facet.items].filter((item) => item.label !== 'out_of_stock'),
      };
    }
    case FILTER_PRODUCT_RANGE: {
      return {
        ...facet,
        items: [...facet.items].sort((a, b) => {
          const aIndex = productRangeOptionsOrder.indexOf(a.label);
          const bIndex = productRangeOptionsOrder.indexOf(b.label);
          if (aIndex === -1) {
            return 1;
          } else if (bIndex === -1) {
            return -1;
          }
          return aIndex - bIndex;
        }),
      };
    }
    case FILTER_WET_GRIP:
    case FILTER_NOISE_LEVEL: {
      return {
        ...facet,
        items: [...facet.items].sort((a, b) => {
          if (a.label < b.label) {
            return -1;
          }
          if (a.label > b.label) {
            return 1;
          }
          return 0;
        }),
      };
    }
    default:
      return facet;
  }
};
