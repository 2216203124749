import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { calculateDiscountPrice } from 'components/Discount';
import { getBasketOtherSection, getBasketVehicles } from 'domains/basket/Basket.store';
import { getDiscount, getPrice } from 'domains/references';
import { ClientView, GarageView, SparePartsViewType } from 'domains/user';
import { Flex, MarginBox, Space, Text } from 'UI';
import { getData, hasData, textFormatter } from 'utils';

export const TireBasketPriceInfo = ({
  referenceNumber,
  vehicleKey,
  sparePartsView,
}: {
  referenceNumber: string;
  vehicleKey?: string;
  sparePartsView: SparePartsViewType;
}) => {
  const { t } = useTranslation();
  const vehicles = useSelector(getBasketVehicles);
  const price = useSelector((state: RootState) => getPrice(state, referenceNumber));
  const discount = useSelector((state: RootState) => getDiscount(state, referenceNumber));
  const priceData = getData(price);
  const otherSection = useSelector(getBasketOtherSection);
  const referencesOfCurrentVeh = useMemo(
    () => vehicles.find((vehicle) => vehicle.vehicleDetail.vehicleKey === vehicleKey)?.references,
    [vehicleKey, vehicles],
  );
  const isApplicableOrInCurrVehCart = useCallback(() => !!vehicleKey, [vehicleKey]);

  const basketReferences = isApplicableOrInCurrVehCart() ? referencesOfCurrentVeh : otherSection?.references;
  const currentBasketReference = basketReferences?.find((ref) => ref.referenceNumber === referenceNumber);
  const refQuantity = currentBasketReference?.quantity as number;

  if (!refQuantity || !hasData(price)) {
    return null;
  }

  return (
    <MarginBox mt={2}>
      <Flex direction={'row'}>
        <Space size={10} direction={'horizontal'}>
          <Text type={'light_14_black_85'} transform={'capitalize'}>
            {t('catalog.tires.reference_card.subtotal_in_basket', 'Subtotal ({{count}} items) :', {
              count: refQuantity,
            })}
          </Text>

          {sparePartsView === ClientView && (
            <>
              <Text type={'light_14_bold_black_85'}>
                {textFormatter.formatCurrency(
                  refQuantity * Number(priceData?.clientView?.recommendedPriceVatExcluded),
                  priceData?.currency ?? '',
                )}
              </Text>
            </>
          )}
          {sparePartsView === GarageView && priceData?.garageView && (
            <>
              <Text type={'light_14_bold_black_85'}>
                {textFormatter.formatCurrency(
                  refQuantity * calculateDiscountPrice(discount, priceData, false),
                  priceData.currency,
                )}
              </Text>
            </>
          )}
        </Space>
      </Flex>
    </MarginBox>
  );
};
