import csCZ from 'antd/lib/locale/cs_CZ';
import deDE from 'antd/lib/locale/de_DE';
import enGB from 'antd/lib/locale/en_GB';
import enUS from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import itIT from 'antd/lib/locale/it_IT';
import nlNL from 'antd/lib/locale/nl_NL';
import plPL from 'antd/lib/locale/pl_PL';
import ptPT from 'antd/lib/locale/pt_PT';
import roRO from 'antd/lib/locale/ro_RO';
import ruRU from 'antd/lib/locale/ru_RU';
import trTR from 'antd/lib/locale/tr_TR';
import { Locale } from 'antd/lib/locale-provider';
import { Country } from 'utils/i18n/Country';

export class Language {
  static readonly german = new Language('de', 'Deutsch', Country.DE, 'de-DE', deDE);
  static readonly germanAustria = new Language('de-AT', 'Deutsch (Austria)', Country.AT, 'de-AT', deDE);
  static readonly germanSwitzerland = new Language('de-CH', 'Deutsch (Switzerland)', Country.CH, 'de-CH', deDE);
  static readonly english = new Language('en', 'English', Country.GB, 'en-GB', enGB);
  static readonly englishUsa = new Language('us', 'English (United States)', Country.USA, 'en-US', enUS);
  static readonly spanish = new Language('es', 'Español', Country.ES, 'es-ES', esES);
  static readonly french = new Language('fr', 'Français', Country.FR, 'fr-FR', frFR);
  static readonly frenchBelgium = new Language('fr-BE', 'Français (Belgium)', Country.BE, 'fr-BE', frFR);
  static readonly frenchSwitzerland = new Language('fr-CH', 'Français (Switzerland)', Country.CH, 'fr-CH', frFR);
  static readonly italian = new Language('it', 'Italiano', Country.IT, 'it-IT', itIT);
  static readonly italianSwitzerland = new Language('it-CH', 'Italiano (Switzerland)', Country.CH, 'it-CH', itIT);
  static readonly dutch = new Language('nl', 'Nederlands', Country.NL, 'nl-NL', nlNL);
  static readonly dutchBelgium = new Language('nl-BE', 'Nederlands (Belgium)', Country.BE, 'nl-BE', nlNL);
  static readonly polish = new Language('pl', 'Polski', Country.PL, 'pl-PL', plPL);
  static readonly portuguese = new Language('pt', 'Português', Country.PT, 'pt-PT', ptPT);
  static readonly romanian = new Language('ro', 'Română', Country.RO, 'ro-RO', roRO);
  static readonly turkish = new Language('tr', 'Türkçe', Country.TR, 'tr-TR', trTR);
  static readonly czech = new Language('cs', 'Čeština', Country.CZ, 'cs-CZ', csCZ);
  static readonly russian = new Language('ru', 'Русский', Country.RU, 'ru-RU', ruRU);
  static readonly DEFAULT = Language.french;

  private constructor(
    public readonly key: string,
    public readonly name: string,
    public readonly country: Country,
    public readonly locale: string,
    public readonly antdLocale: Locale,
  ) {}

  toString(): string {
    return this.key;
  }

  getLanguage(): string {
    return this.locale.split('-')[0];
  }

  getCountryCode(): string {
    return this.country.key;
  }

  public static languagesAreEqual(lang1?: Language, lang2?: Language): boolean {
    if (!lang1 || !lang2) {
      return false;
    }
    return lang1.key === lang2.key;
  }

  // All of these languages are currently supported in RParts, but not translated in PoEditor
  public static listWebLanguages(): Array<Language> {
    return [
      this.english,
      this.german,
      this.germanAustria,
      this.french,
      this.italian,
      this.dutch,
      this.polish,
      this.portuguese,
      this.romanian,
      this.spanish,
      this.turkish,
    ];
  }

  public static listDocumentLanguages(): Array<Language> {
    return [
      this.english,
      this.englishUsa,
      this.czech,
      this.dutch,
      this.dutchBelgium,
      this.german,
      this.germanAustria,
      this.germanSwitzerland,
      this.french,
      this.frenchBelgium,
      this.frenchSwitzerland,
      this.italian,
      this.italianSwitzerland,
      this.polish,
      this.portuguese,
      this.romanian,
      this.spanish,
      this.russian,
      this.turkish,
    ];
  }

  public static listAllLanguages(): Array<Language> {
    return [
      this.german,
      this.germanAustria,
      this.germanSwitzerland,
      this.english,
      this.englishUsa,
      this.spanish,
      this.french,
      this.frenchBelgium,
      this.frenchSwitzerland,
      this.italian,
      this.italianSwitzerland,
      this.dutch,
      this.dutchBelgium,
      this.polish,
      this.portuguese,
      this.romanian,
      this.turkish,
      this.czech,
      this.russian,
    ];
  }

  public static findInWebLang(lang: string): Language | undefined {
    return this.listWebLanguages().filter((l) => l.key === lang)[0] || undefined;
  }

  public static findByKey(languages: Array<Language>, lang: string): Language | undefined {
    return languages.filter((l) => l.key === lang)[0] || undefined;
  }

  public static findByLocale(languages: Array<Language>, lang: string): Language | undefined {
    const fallbackLanguageKey: string[] | undefined = fallbackLanguages[lang] ?? undefined;
    return (
      languages.filter((l) => {
        return l.locale === lang || l.key === (fallbackLanguageKey ? fallbackLanguageKey[0] : '');
      })[0] || undefined
    );
  }
}

export const fallbackLanguages: { [x: string]: string[] } = {
  [Language.germanSwitzerland.key]: [Language.german.key],
  [Language.frenchSwitzerland.key]: [Language.french.key],
  [Language.frenchBelgium.key]: [Language.french.key],
  [Language.italianSwitzerland.key]: [Language.italian.key],
};

export const webLanguages = Language.listWebLanguages();

export const documentLanguages = Language.listDocumentLanguages();

export const allLanguages = Language.listAllLanguages();
