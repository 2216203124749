import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ClarionLinkResponse } from '@1po/1po-bff-fe-spec/generated/clarion/ClarionLinkResponse';
import { SText } from 'components/Page/SubFooter/SubFooter.styled';
import { clearClarionLinkRequest, fetchClarionLinkRequest, getClarionLink } from 'domains/clarion/Clarion.store';
import { getSearchData, hasData, isLoading } from 'utils';

interface ClarionLinkProps {
  text: string;
}

export const ClarionLink = ({ text }: ClarionLinkProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const clarionLink = useSelector(getClarionLink);
  //
  const handlePostRedirect = () => {
    dispatch(fetchClarionLinkRequest());
  };

  useEffect(() => {
    if (!hasData(clarionLink.data)) {
      return;
    }

    const linkData = getSearchData(clarionLink);

    const form = document.createElement('form');
    form.method = 'POST';
    form.target = '_blank';
    form.action = linkData?.url ?? '';

    try {
      if (linkData as ClarionLinkResponse) {
        const inputLoginData = document.createElement('input');
        inputLoginData.type = 'hidden';
        inputLoginData.name = 'login_data';
        inputLoginData.value = linkData?.login_data ?? '';
        form.appendChild(inputLoginData);

        const inputLoginIv = document.createElement('input');
        inputLoginIv.type = 'hidden';
        inputLoginIv.name = 'login_iv';
        inputLoginIv.value = linkData?.login_iv ?? '';
        form.appendChild(inputLoginIv);

        document.body.appendChild(form);
        form.submit();
      }
    } finally {
      form.parentNode && document.body.removeChild(form);
      dispatch(clearClarionLinkRequest());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clarionLink.searchStatus]);

  return (
    <>
      {isLoading(clarionLink.searchStatus) ? (
        <SText type={'dark_14_white_65'} noWrap>
          {t('link.redirecting_in_progress', 'Redirecting...')}
        </SText>
      ) : (
        <SText onClick={handlePostRedirect} type={'dark_14_white_65'} noWrap>
          {text}
        </SText>
      )}
    </>
  );
};
