/* eslint-disable max-len */
import { ReferenceStockDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/model/ReferenceStockDetail';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { WsResponse } from '@1po/1po-bff-fe-spec/generated/common/WsResponse';
import { GetIAMTiresRequest } from '@1po/1po-bff-fe-spec/generated/tire/iam/request/GetIAMTiresRequest';
import { GetIAMTiresResponse } from '@1po/1po-bff-fe-spec/generated/tire/iam/response/GetIAMTiresResponse';
import { VehicleTire } from '@1po/1po-bff-fe-spec/generated/tire/model/VehicleTire';
import { DataHubTiresResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/DataHubTiresResponse';
import { TireBrandCategoriesResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireBrandCategoriesResponse';
import { TireFulltextSearchResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireFulltextSearchResponse';
import { TireSearchResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireSearchResponse';
import { TireUniqueDimensionsResponse } from '@1po/1po-bff-fe-spec/generated/tire/response/TireUniqueDimensionsResponse';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { RootState } from 'app/AppStore';
import { LIFECYCLE_W1, LIFECYCLE_W4 } from 'domains/catalog/Catalog.types';
import { fetchPricesAndStocks } from 'domains/references/References.saga';
import {
  searchTires,
  sendIAMTiresRequest,
  sendTireBrandCategoriesRequest,
  sendTiresFulltextSearchRequest,
  sendTireUniqueDimensionsRequest,
} from 'domains/tires/Tire.api';
import * as actions from 'domains/tires/Tire.store';
import {
  getTireSearchResult,
  setFulltextSearchData,
  setFulltextSearchStatus,
  setIAMTires,
  setIAMTiresNoDataStatus,
  setSearchDimensions,
  setTireBrandCategories,
  setTireBrandCategoriesSearchStatus,
  setTireResult,
  setTireSearchStatus,
  setVehicleTires,
} from 'domains/tires/Tire.store';
import { getUserContext } from 'domains/user';
import { LOADING, localeCompareSort, sagaGuard, select } from 'utils';
import { toSearchParams } from './Tire.mapper';
import { getLastVehicleDetail } from '../catalog/Catalog.store';

export function* tireProductsSearchRequestSaga({
  payload,
}: ReturnType<typeof actions.tireProductsSearchRequestSaga>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const MINSIZE_FIRST_3_PARAMETERS = 3 + 2 + 2;
  const query = payload.query;

  if (!query) {
    return;
  }

  if (query && query.length < MINSIZE_FIRST_3_PARAMETERS) {
    return;
  }

  if (query.split(' ').length < 3) {
    return;
  }

  const {
    searchParamsBase64,
    width,
    speedIndicator,
    chargeIndicator,
    doubleChargeIndicator,
    series,
    diameter,
  } = toSearchParams(query, payload.season, payload.brands, payload.chargeIndicatorSeparator);

  const catalogTires = yield* select((state: RootState) => getTireSearchResult(state, { searchParamsBase64 }));

  if (!catalogTires) {
    yield put(setTireSearchStatus({ searchParamsBase64, status: LOADING }));
    yield put(
      searchTires({
        quantity: 1,
        userContext,
        searchParamsBase64,
        season: payload.season,
        brands: payload.brands,
        width,
        speedIndicator,
        chargeIndicator,
        doubleChargeIndicator,
        series,
        diameter,
      }),
    );
  }
}

export function* tireProductsSearchResponseSaga(action: WsResponse<TireSearchResponse>): SagaIterator {
  const { tires } = action.payload;
  if (tires) {
    const stockRequest = tires
      .filter((t) => !(t.lifeCycle === LIFECYCLE_W1 || t.lifeCycle === LIFECYCLE_W4))
      .map((t) => {
        return {
          referenceNumber: t.partNumber,
          type: 'TIRE',
          quantity: 1,
        } as ReferenceStockDetail;
      });

    const priceRequest = tires
      .filter((t) => !(t.lifeCycle === LIFECYCLE_W4))
      .map((t) => {
        return {
          referenceNumber: t.partNumber,
          commercialFamily: '',
        } as ReferenceTradingDataRequestDetail;
      });

    yield call(fetchPricesAndStocks, priceRequest, stockRequest);
    yield put(setTireResult(action.payload));
  }
}

export function* vehicleTiresResponseSaga(action: WsResponse<DataHubTiresResponse>): SagaIterator {
  const { vin, tires } = action.payload;
  if (tires === undefined || tires.length === 0) return;

  const tiresWithId = tires.map((item, idx) => {
    const basicMount = item.basicMount && {
      ...item.basicMount,
      frontTireId: `basicMount-front-${item.basicMount?.frontTyreSize}-${item.basicMount?.frontRimSize}-${idx}`,
      rearTireId: `basicMount-rear-${item.basicMount?.rearTyreSize}-${item.basicMount?.rearRimSize}-${idx}`,
    };
    const approvedTires = item.approvedTires?.map((approvedTire, approvedIdx) => {
      return {
        ...approvedTire,
        frontTireId: `approvedTires-front-${approvedTire?.frontTyreSize}-${approvedTire?.frontRimSize}-${idx}-${approvedIdx}`,
        rearTireId: `approvedTires-rear-${approvedTire?.rearTyreSize}-${approvedTire?.rearRimSize}-${idx}-${approvedIdx}`,
      };
    });
    return {
      ...item,
      basicMount,
      approvedTires,
    };
  });

  yield put(setVehicleTires({ vehicleKey: vin, tires: tiresWithId[0] }));
}

export function* fetchIAMTiresRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  const vehicleKey = vehicleDetail?.vehicleKey;
  const versionCode = vehicleDetail?.iamVehicle?.versionCode;
  const vehicleCountryCode = vehicleDetail?.country ?? userContext.r1Country;

  if (
    vehicleKey === undefined ||
    vehicleDetail?.iamVehicle?.versionCode === undefined ||
    (vehicleCountryCode === undefined && userContext.r1Country)
  ) {
    return;
  }

  const vehicleContext = { vehicleKey, versionCode, vehicleCountryCode };

  const request: GetIAMTiresRequest = {
    vehicleContext,
    userContext,
  };

  yield put(setIAMTiresNoDataStatus({ vehicleCode: vehicleContext.versionCode, status: LOADING }));
  yield put(sendIAMTiresRequest(request));
}

export function* fetchIAMTiresResponseSaga(action: WsResponse<GetIAMTiresResponse>): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const vehicleKey = vehicleDetail?.vehicleKey;

  const { iamTires } = action.payload;
  if (!vehicleKey || !iamTires) {
    return;
  }

  const sameSizesFiltered = iamTires.filter((t) => t.frontTyreSize === t.rearTyreSize);
  const differentSizeTires = iamTires.filter((t) => t.frontTyreSize !== t.rearTyreSize);

  const sameSizeTires: VehicleTire[] = sameSizesFiltered.map((sameSizeTire, idx) => {
    return {
      ...sameSizeTire,
      frontTireId: `same-size-${idx}-${sameSizeTire.frontTyreSize}-${sameSizeTire.frontRimSize}`,
    };
  });
  const differentSizes: VehicleTire[] = differentSizeTires.map((differentSizeTire, idx) => {
    return {
      ...differentSizeTire,
      frontTireId: `different-size-front-${idx}-${differentSizeTire.frontTyreSize}-${differentSizeTire.frontRimSize}`,
      rearTireId: `different-size-rear-${idx}-${differentSizeTire.rearTyreSize}-${differentSizeTire.rearRimSize}`,
    };
  });

  // Filter, as we do not want to display same size tires with various rear rim sizes
  const sameSizes = sameSizeTires.filter(
    (value, index, self) =>
      index === self.findIndex((t) => t.frontTyreSize === value.frontTyreSize && t.frontRimSize === value.frontRimSize),
  );

  yield put(
    setIAMTires({
      vehicleKey,
      iamTires: {
        sameSizes,
        differentSizes,
      },
    }),
  );
}

export function* tiresFulltextSearchRequestSaga({
  payload,
}: ReturnType<typeof actions.tiresFulltextSearchRequestSaga>): SagaIterator {
  const userContext = yield* select(getUserContext);

  yield put(setFulltextSearchStatus(LOADING));
  yield put(
    sendTiresFulltextSearchRequest({
      userContext,
      fulltextSearch: payload.fulltextSearch,
    }),
  );
}

export function* tiresFulltextSearchResponseSaga(action: WsResponse<TireFulltextSearchResponse>): SagaIterator {
  const { results } = action.payload;
  yield put(setFulltextSearchData(results));
}

export function* fetchUniqueTireDimensionsRequestSaga(): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(sendTireUniqueDimensionsRequest({ userContext }));
}

export function* uniqueDimensionsResponseSaga(action: WsResponse<TireUniqueDimensionsResponse>): SagaIterator {
  const { chargeIndicator, diameter, width, series, speedIndicator } = action.payload;
  const filteredDiameter = diameter.map((item) => item.trim()).filter((val, index) => diameter.indexOf(val) === index);

  yield put(
    setSearchDimensions({
      chargeIndicator: localeCompareSort(chargeIndicator),
      diameter: localeCompareSort(filteredDiameter),
      width: localeCompareSort(width),
      series: localeCompareSort(series),
      speedIndicator: localeCompareSort(speedIndicator),
    }),
  );
}

export function* tireBrandCategoriesRequestSaga(): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(setTireBrandCategoriesSearchStatus({ searchStatus: LOADING }));
  yield put(sendTireBrandCategoriesRequest({ userContext }));
}

export function* tireBrandCategoriesResponseSaga(action: WsResponse<TireBrandCategoriesResponse>): SagaIterator {
  yield put(setTireBrandCategories(action.payload));
}

export function* TireSagas(): SagaIterator {
  yield takeEvery(actions.tireProductsSearchRequestSaga.type, sagaGuard(tireProductsSearchRequestSaga));
  yield takeEvery(actions.tireProductsSearchResponseSaga.type, sagaGuard(tireProductsSearchResponseSaga));
  yield takeEvery(actions.vehicleTiresResponseSaga.type, sagaGuard(vehicleTiresResponseSaga));
  yield takeEvery(actions.fetchIAMTiresRequestSaga.type, sagaGuard(fetchIAMTiresRequestSaga));
  yield takeEvery(actions.fetchIAMTiresResponseSaga.type, sagaGuard(fetchIAMTiresResponseSaga));
  yield takeEvery(actions.tiresFulltextSearchRequestSaga.type, sagaGuard(tiresFulltextSearchRequestSaga));
  yield takeEvery(actions.tiresFulltextSearchResponseSaga.type, sagaGuard(tiresFulltextSearchResponseSaga));
  yield takeEvery(actions.fetchUniqueTireDimensionsRequestSaga.type, sagaGuard(fetchUniqueTireDimensionsRequestSaga));
  yield takeEvery(actions.uniqueDimensionsResponseSaga.type, sagaGuard(uniqueDimensionsResponseSaga));
  yield takeEvery(actions.tireBrandCategoriesRequestSaga.type, sagaGuard(tireBrandCategoriesRequestSaga));
  yield takeEvery(actions.tireBrandCategoriesResponseSaga.type, sagaGuard(tireBrandCategoriesResponseSaga));
}
