import React, { useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { HEADER_HEIGHT } from 'components/Page/Header/TopHeader/TopHeader';
import { SIDEBAR_WIDTH } from 'components/Page/PageSideBar/PageSideBar';
import { MenuItem } from 'components/Page/PageSideBar/PageSideBar.styled';
import { TextWrapper } from 'components/ProfileInfo/ProfileInfo';
import SubCategoriesMenu from 'components/TreeMenu/SubCategoriesMenu';
import { formatCategoryLabelToDataCy } from 'domains/catalog/Catalog.utils';
import Banner from 'pages/UniversalProductsPage/Banner';
import { theme } from 'styles';
import {
  Box,
  Flex,
  Image as Img,
  MarginBox,
  Modal,
  MODAL_HEADER_HEIGHT,
  MODAL_PADDING_X,
  MODAL_PADDING_Y,
  PUBLIC,
  Text,
} from 'UI';

export type TreeType = {
  nodeId: string;
  label: string;
  childCategories?: TreeType[];
};

export interface TreeMenuProps {
  tree: TreeType[];
  baseUrl: string;
  disableLevel1Click?: boolean;
  disableLevel2Click?: boolean;
}

const images = [
  { name: 'Renault', path: 'sidebar/renault.png' },
  { name: 'Ixtar', path: 'sidebar/ixtar.png' },
  { name: 'Motrio', path: 'sidebar/motrio.png' },
  { name: 'Ixell', path: 'sidebar/ixell.png' },
  { name: 'Value', path: 'sidebar/value.png' },
];

const TreeMenu = ({ tree, baseUrl, disableLevel1Click, disableLevel2Click }: TreeMenuProps) => {
  const history = useHistory();
  const [activeLvl1, setActiveLvl1] = useState<string>('');

  const enterTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const leaveTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const clearEnterTimeout = () => {
    if (enterTimeoutRef.current) {
      clearTimeout(enterTimeoutRef.current);
      enterTimeoutRef.current = null;
    }
  };

  const clearLeaveTimeout = () => {
    if (leaveTimeoutRef.current) {
      clearTimeout(leaveTimeoutRef.current);
      leaveTimeoutRef.current = null;
    }
  };

  const handleMouseEnterItem = (categoryKey: string) => {
    clearEnterTimeout();
    clearLeaveTimeout();
    enterTimeoutRef.current = setTimeout(() => {
      setActiveLvl1(categoryKey);
    }, 100);
  };

  const handleMouseLeaveItem = () => {
    clearEnterTimeout();
    leaveTimeoutRef.current = setTimeout(() => {
      setActiveLvl1('');
    }, 300);
  };

  const handleMouseEnterModal = () => {
    clearLeaveTimeout();
  };

  const handleMouseLeaveModal = () => {
    leaveTimeoutRef.current = setTimeout(() => {
      setActiveLvl1('');
    }, 300);
  };

  const handleLvl1Click = (nodeId: string) => {
    if (disableLevel1Click) {
      return;
    }
    setActiveLvl1('');
    history.push(`${baseUrl}/${nodeId}`);
  };

  const visibleWidthRatio = 0.9;
  const categoriesMargin = 20;
  const maxWidth = (window.innerWidth - SIDEBAR_WIDTH) * visibleWidthRatio;
  const minHeight = window.innerHeight - HEADER_HEIGHT - MODAL_PADDING_Y - 2 * categoriesMargin;

  const showBanner = () => {
    const activeLevel1Item = tree.find((level1) => level1.nodeId === activeLvl1);
    const subcategoryCount = activeLevel1Item?.childCategories?.length ?? 0;
    const cardWidth = 332;
    const fitCount = Math.floor((maxWidth - 2 * MODAL_PADDING_X) / cardWidth);
    return subcategoryCount <= fitCount;
  };

  return (
    <Flex direction={'column'} dataCy={'universal-catalog'} gap={8} size={0}>
      {tree.map((lvl1) => {
        return (
          <MenuItem
            key={lvl1.nodeId}
            $hoverActive={activeLvl1 === lvl1.nodeId}
            submenu
            onClick={() => handleLvl1Click(lvl1.nodeId)}
            onMouseEnter={() => handleMouseEnterItem(lvl1.nodeId)}
            onMouseLeave={handleMouseLeaveItem}
            data-cy={formatCategoryLabelToDataCy(lvl1.label, 'tab')}
          >
            <TextWrapper maxHeight={44}>
              <Text type={'text_dim'} transform={'capitalize'}>
                {lvl1.label}
              </Text>
            </TextWrapper>
          </MenuItem>
        );
      })}
      <Modal
        title={null}
        footer={null}
        maskClosable={true}
        open={activeLvl1 !== ''}
        zIndex={897}
        backgroundColor={theme.color.white}
        style={{
          position: 'absolute',
          top: HEADER_HEIGHT - MODAL_HEADER_HEIGHT,
          left: SIDEBAR_WIDTH,
          maxWidth,
        }}
      >
        <Box onMouseLeave={handleMouseLeaveModal} onMouseEnter={() => handleMouseEnterModal()}>
          <MarginBox mx={categoriesMargin} my={categoriesMargin}>
            <Flex wrap={'wrap'} direction={'column'} minHeight={minHeight}>
              {tree?.map((lvl1) => {
                return (
                  activeLvl1 === lvl1.nodeId && (
                    <SubCategoriesMenu
                      key={lvl1.nodeId}
                      baseUrl={baseUrl}
                      level1Label={lvl1.label}
                      level1NodeId={lvl1.nodeId}
                      treeData={lvl1.childCategories ?? []}
                      setActiveCategory={setActiveLvl1}
                      disableLevel2Click={disableLevel2Click}
                    />
                  )
                );
              })}
              {showBanner() && (
                <Flex align={'flex-end'} justify={'flex-end'}>
                  <Flex direction={'column'}>
                    <Banner />
                    <Box height={48} />
                    <Flex justify={'space-between'} gap={24}>
                      {images.map((image) => (
                        <Img height={70} key={image.name} alt={image.name} src={image.path} type={PUBLIC} />
                      ))}
                    </Flex>
                    <Box height={48} />
                  </Flex>
                </Flex>
              )}
            </Flex>
          </MarginBox>
        </Box>
      </Modal>
    </Flex>
  );
};

export default TreeMenu;
