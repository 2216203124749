import React from 'react';
import { useTranslation } from 'react-i18next';
import { Season } from '@1po/1po-bff-fe-spec/generated/tire/model/Season';
import { TFunction } from 'i18next';
import {
  CommingSoonIcon,
  ExtraLoadIcon,
  PmsfRoundIcon,
  RunFlatRoundIcon,
  SeasonsRoundIcon,
  SelfSealRoundIcon,
  SnowflakeRoundIcon,
  SunRoundIcon,
} from 'assets/icons';
import { POSITIVE_FEATURE_VALUES } from 'components/Filter/Filter.types';
import { LIFECYCLE_W1 } from 'domains/catalog/Catalog.types';
import { SUMMER, SUMMER_WINTER, WINTER } from 'domains/tires/Tire.types';
import { Flex, Icon, IconType, WithTooltip } from 'UI';

const getSeasonTooltipLabel = (t: TFunction, season: Season | undefined): string => {
  switch (season) {
    case 'SUMMER':
      return t('catalog.tires.reference.summer_tire', 'Summer tire');
    case 'WINTER':
      return t('catalog.tires.reference.winter_tire', 'Winter tire');
    case 'SUMMER_WINTER':
      return t('catalog.tires.reference.all_seasons_tire', 'All seasons tire');
    default:
      return '';
  }
};
const getSeasonIcon = (season: Season): IconType => {
  switch (season) {
    case SUMMER:
      return SunRoundIcon;
    case WINTER:
      return SnowflakeRoundIcon;
    case SUMMER_WINTER:
    default:
      return SeasonsRoundIcon;
  }
};

interface TireFeaturesIcons {
  season?: Season;
  extraLoad?: string;
  runFlat?: string;
  pmsf?: string;
  selfSeal?: string;
  lifeCycle?: string;
}

const FeatureIconWrapper = ({ title, IconComponent }: { title: string; IconComponent: IconType }) => {
  return (
    <WithTooltip title={title}>
      <Icon size={40} IconComponent={IconComponent} mr={15} noPointer />
    </WithTooltip>
  );
};

const TireFeaturesIcons = ({ season, extraLoad, runFlat, pmsf, selfSeal, lifeCycle }: TireFeaturesIcons) => {
  const { t } = useTranslation();

  const extraLoadEnabled = extraLoad ? extraLoad.length > 0 : false;
  const runFlatEnabled = runFlat ? runFlat.length > 0 : false;
  const pmsfEnabled = pmsf ? POSITIVE_FEATURE_VALUES.includes(pmsf) : false;
  const selfSealEnabled = selfSeal ? POSITIVE_FEATURE_VALUES.includes(selfSeal) : false;
  const lifeCycleW1Enabled = lifeCycle ? lifeCycle === LIFECYCLE_W1 : false;

  return (
    <Flex direction={'row'}>
      {lifeCycleW1Enabled && (
        <FeatureIconWrapper
          title={t('catalog.tires.reference.available_soon', 'Available soon')}
          IconComponent={CommingSoonIcon}
        />
      )}
      {season && (
        <FeatureIconWrapper
          title={getSeasonTooltipLabel(t, season as Season)}
          IconComponent={getSeasonIcon(season as Season)}
        />
      )}
      {runFlatEnabled && (
        <FeatureIconWrapper
          title={t('catalog.tires.reference.run_flat', 'Run flat')}
          IconComponent={RunFlatRoundIcon}
        />
      )}
      {extraLoadEnabled && (
        <FeatureIconWrapper
          title={t('catalog.tires.reference.reinforced_tire', 'Reinforced Tire (Extra Load)')}
          IconComponent={ExtraLoadIcon}
        />
      )}
      {pmsfEnabled && (
        <FeatureIconWrapper
          title={t('catalog.tires.reference.pmsf', 'Certified for Winter Conditions (Peak / Mud / Snow / Flake)')}
          IconComponent={PmsfRoundIcon}
        />
      )}
      {selfSealEnabled && (
        <FeatureIconWrapper
          title={t('catalog.tires.reference.self_seal', 'Self Seal')}
          IconComponent={SelfSealRoundIcon}
        />
      )}
    </Flex>
  );
};
export default TireFeaturesIcons;
