import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { TFunction } from 'i18next';
import styled from 'styled-components';
import { AngleRightIcon, HeadphonesIcon, PercentIcon, RoadIcon, TruckIcon } from 'assets/icons';
import { useFetchUniqueTireDimensions } from 'domains/tires/Tire.requests';
import { getTireSearchParams, setInitialState as setInitialTireState } from 'domains/tires/Tire.store';
import { SparePartsViewType } from 'domains/user';
import TireReferencesSection from 'pages/TiresPage/TireReferencesSection/TireReferencesSection';
import TireSearch from 'pages/TiresPage/TireSearch';
import { theme } from 'styles';
import {
  Box,
  CenterFlex,
  Container,
  defaultPadding,
  emptyPadding,
  Flex,
  Grid,
  Icon,
  MarginBox,
  notifyNotImplemented,
  Text,
} from 'UI';
import { ProductPreview } from 'UI/ProductPreview';
import { useBreakpointSelectorFull } from 'utils';
import { useResetScroll } from 'utils/hooks/useResetScroll';
import { IconButtonLabel, SBackground } from './TiresPage.styled';

interface ProductInterface {
  imageBase64: string;
  productName: string;
  referenceNumber: string;
  sparePartsView: SparePartsViewType;
  handleAddToCart: () => void;
}

const productData: ProductInterface[] = [];

const IconCircle = styled.div`
  width: 70px;
  height: 70px;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.color['grey_metal']};
`;

const getIconButtons = (t: TFunction) => {
  return [
    {
      button: (
        <IconCircle>
          <Icon
            IconComponent={TruckIcon}
            size={36}
            background={theme.color['grey_metal']}
            onClick={() => notifyNotImplemented()}
            noPointer
          />
        </IconCircle>
      ),
      label: `${t('catalog.tires.free_delivery', 'Free Delivery')}`,
    },
    {
      button: (
        <IconCircle>
          <Icon
            IconComponent={PercentIcon}
            size={36}
            background={theme.color['grey_metal']}
            onClick={() => notifyNotImplemented()}
            noPointer
          />
        </IconCircle>
      ),
      label: `${t('catalog.tires.special_offers', 'Special offers')}`,
    },
    {
      button: (
        <IconCircle>
          <Icon
            IconComponent={HeadphonesIcon}
            size={36}
            background={theme.color['grey_metal']}
            onClick={() => notifyNotImplemented()}
            noPointer
          />
        </IconCircle>
      ),
      label: `${t('catalog.tires.customer_services', 'Customer services')}`,
    },
    {
      button: (
        <IconCircle>
          <Icon
            IconComponent={RoadIcon}
            size={36}
            background={theme.color['grey_metal']}
            onClick={() => notifyNotImplemented()}
            noPointer
          />
        </IconCircle>
      ),
      label: `${t('catalog.tires.plus_200000_references', '+20 000 références')}`,
    },
  ];
};

const IconButtonsSections = () => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();
  const iconButtons = getIconButtons(t);
  return (
    <Box background={theme.color.grey95}>
      <MarginBox mt={30} mb={30}>
        <Grid columns={breakpointSelectorFull({ xs: 1, sm: 2, md: 2, lg: 4, xl: 4, xxl: 4 })}>
          {iconButtons.map((item, index) => (
            <Box height={210} key={`icon-${index}`}>
              <CenterFlex>
                <Box>
                  <CenterFlex>{item.button}</CenterFlex>
                  <MarginBox mt={24}>
                    <IconButtonLabel type={'h5_bold'}>{item.label}</IconButtonLabel>
                  </MarginBox>
                </Box>
              </CenterFlex>
            </Box>
          ))}
        </Grid>
      </MarginBox>
    </Box>
  );
};

const ConsumableSection = () => {
  const { t } = useTranslation();
  const breakpointSelectorFull = useBreakpointSelectorFull();

  return (
    <>
      <MarginBox mx={40} mb={30}>
        <Flex justify={'space-between'} align={'center'} onClick={notifyNotImplemented}>
          <Text type={'h1'}>{t('catalog.consumable_title', 'Consumable Title')}</Text>
          <Text type={'lead_dim'} displayStyle={'link'} cursor={'pointer'}>
            <Flex direction={'row'} align={'center'}>
              <Text type={'lead_dim'} displayStyle={'link'} cursor={'pointer'}>
                {t('catalog.reference_card.additional_info.see_more', 'See more')}
              </Text>
              <MarginBox mr={30} />
              <Icon size={19} IconComponent={AngleRightIcon} color={theme.color['info']} />
            </Flex>
          </Text>
        </Flex>
      </MarginBox>
      <CenterFlex>
        <Box width={breakpointSelectorFull({ xs: 270, sm: 540, md: 540, lg: 1080, xl: 1080, xxl: 1080 })}>
          <Grid gutter={[30, 30]} columns={breakpointSelectorFull({ xs: 1, sm: 1, md: 2, lg: 2, xl: 4, xxl: 4 })}>
            {productData.map((p, index) => (
              <ProductPreview
                imageUrl={p.imageBase64}
                productName={p.productName}
                referenceNumber={p.referenceNumber}
                sparePartsView={p.sparePartsView}
                key={`product-${index}`}
              />
            ))}
          </Grid>
        </Box>
      </CenterFlex>
    </>
  );
};

const TiresPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const searchParamsBase64 = useSelector(getTireSearchParams);

  useResetScroll();
  useFetchUniqueTireDimensions();

  useEffect(() => {
    return history.listen(() => {
      dispatch(setInitialTireState());
    });
  }, [history, dispatch]);

  return (
    <Container padding={emptyPadding} size={'xxl'}>
      <Flex direction={'column'}>
        <SBackground image={'tires/tires_background.jpg'} isMainSearch={!searchParamsBase64}>
          <TireSearch />
        </SBackground>
        <Flex direction={'column'} justify={'space-between'}>
          {searchParamsBase64 ? (
            <Container padding={defaultPadding} size={'xxl'}>
              <TireReferencesSection searchParamsBase64={searchParamsBase64} />
            </Container>
          ) : (
            <>
              <IconButtonsSections />
              {productData.length > 0 && <ConsumableSection />}
            </>
          )}
        </Flex>
      </Flex>
    </Container>
  );
};

export default TiresPage;
