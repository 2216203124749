import styled from 'styled-components';
import { theme } from 'styles';
import { Box, Flex } from 'UI';

export const SCard = styled.div<{ isSquared?: boolean; isSelected?: boolean }>`
  position: relative;
  background-color: ${theme.color.white};
  padding: 15px 15px;
  margin-bottom: 15px;
  transition: height 2s;
  width: 100%;

  border-radius: ${({ isSquared }) => (isSquared ? '14px 14px 0 0' : '14px')};
  box-shadow: ${({ theme, isSelected }) =>
    isSelected ? `0 10px 7px 5px ${theme.color.grey50} ` : `0 4px 10px 0 ${theme.color.grey75}`};
`;

export const SBrandImageBox = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.45;
`;

export const SReferenceCardLaborTimesWrapper = styled(Flex)<{ isSelected?: boolean }>`
  margin-bottom: 15px;
  box-shadow: ${({ theme, isSelected }) =>
    isSelected ? `0 10px 7px 5px ${theme.color.grey50}` : `0 4px 10px 0 ${theme.color.grey75}`};
  border-radius: 0 0 14px 14px;
`;

export const SelectionCard = styled(Box)`
  display: inline-block;
  background-color: ${theme.color.brand_black};
  padding: 5px 15px 20px 15px;
  margin-bottom: -15px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  position: relative;
  z-index: 0;
`;
