import React, { PropsWithChildren } from 'react';
import { Trans } from 'react-i18next';
import { ExclamationTriangleIcon } from 'assets/icons';
import { theme } from 'styles';
import { Box, CenteredEmpty, CenteredSpin, CenterFlex, Flex, Icon, MarginBox, Text } from 'UI';
import { ERROR, LOADING, NO_DATA, NOT_FOUND, SearchData } from 'utils';

interface ErrorWithLabelProps {
  label?: string | React.ReactNode;
  narrow?: boolean;
  light?: boolean;
}

export const DefaultBackendError = (
  <Trans i18nKey={'common.backend_error'}>Data temporarily unavailable, please try again later.</Trans>
);

export const ErrorWithLabel = ({ label = DefaultBackendError, narrow = false, light = false }: ErrorWithLabelProps) => (
  <CenterFlex direction={'column'}>
    {narrow ? (
      <Flex direction={'row'}>
        <MarginBox mr={5}>
          <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} size={20} noPointer />
        </MarginBox>
        <Text type={light ? 'dark_14_medium_white_85' : 'text'}>{label}</Text>
      </Flex>
    ) : (
      <Box align={'center'}>
        <MarginBox mx={15}>
          <Icon IconComponent={ExclamationTriangleIcon} color={theme.color.warning} noPointer />
        </MarginBox>
        <Text type={light ? 'dark_14_medium_white_85' : 'text'}>{label}</Text>
      </Box>
    )}
  </CenterFlex>
);

const defaultLoading = () => <CenteredSpin />;
const defaultNotFound = () => <CenteredEmpty />;
const defaultSkeleton = () => <></>;
const defaultError = () => <ErrorWithLabel />;

export interface DataContainerProps<T> extends PropsWithChildren {
  data: T | NO_DATA | SearchData<T>;
  Loading?: () => JSX.Element;
  NotFound?: () => JSX.Element;
  Skeleton?: () => JSX.Element;
  ErrorState?: () => JSX.Element;
  noDataMarginY?: number;
}

export const DataContainer = <T,>({
  children,
  data,
  Loading = defaultLoading,
  NotFound = defaultNotFound,
  ErrorState = defaultError,
  Skeleton = defaultSkeleton,
  noDataMarginY,
}: DataContainerProps<T>): JSX.Element => {
  switch (data) {
    case LOADING:
      return noDataMarginY ? <MarginBox my={noDataMarginY}>{Loading()}</MarginBox> : Loading();
    case NOT_FOUND:
      return noDataMarginY ? <MarginBox my={noDataMarginY}>{NotFound()}</MarginBox> : NotFound();
    case ERROR:
      return noDataMarginY ? <MarginBox my={noDataMarginY}>{ErrorState()}</MarginBox> : ErrorState();
    case undefined:
      return noDataMarginY ? <MarginBox my={noDataMarginY}>{Skeleton()}</MarginBox> : Skeleton();
    default:
      return <>{children}</>;
  }
};
