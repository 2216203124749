/* eslint-disable max-len */
import { SearchVehicleIAMPartCategoryTreeResponse } from '@1po/1po-bff-fe-spec/generated/catalog/category/response/SearchVehicleIAMPartCategoryTreeResponse';
import { SearchVehiclePartCategoryTreeResponse } from '@1po/1po-bff-fe-spec/generated/catalog/category/response/SearchVehiclePartCategoryTreeResponse';
import { GetIAMLaborTimesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/labor_time/response/GetIAMLaborTimesResponse';
import { GetMaintenancePlanRequest } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/request/GetMaintenancePlanRequest';
import { GetMaintenancePlanResponse } from '@1po/1po-bff-fe-spec/generated/catalog/maintenance_plan/response/GetMaintenancePlanResponse';
import { GetMarketingReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetMarketingReferencesResponse';
import { GetPlateReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetPlateReferencesResponse';
import { GetReferenceDetailsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/dh/response/GetReferenceDetailsResponse';
import { GetIAMReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/references/iam/response/GetIAMReferencesResponse';
import { GetIAMRepairMethodsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsResponse';
import { GetIAMRepairMethodsTreeResponse } from '@1po/1po-bff-fe-spec/generated/catalog/repair_methods/response/GetIAMRepairMethodsTreeResponse';
import { SearchBMMVehicleBrandsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleBrandsResponse';
import { SearchBMMVehicleEnginesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchBMMVehicleEnginesResponse';
import { SearchIAMBMMVehicleBrandsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleBrandsResponse';
import { SearchIAMBMMVehicleModelsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleModelsResponse';
import { SearchIAMBMMVehicleVersionsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchIAMBMMVehicleVersionsResponse';
import { SearchVehicleResponse } from '@1po/1po-bff-fe-spec/generated/catalog/search_vehicle/response/SearchVehicleResponse';
import { GetIAMTechnicalDataDetailsRequest } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/request/GetIAMTechnicalDataDetailsRequest';
import { GetIAMTechnicalDataTreeRequest } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/request/GetIAMTechnicalDataTreeRequest';
import { GetIAMTechnicalDataDetailResponse } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/response/GetIAMTechnicalDataDetailResponse';
import { GetIAMTechnicalDataTreeResponse } from '@1po/1po-bff-fe-spec/generated/catalog/technical_data/response/GetIAMTechnicalDataTreeResponse';
import { GetFullTextAutocompleteResponse } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetFullTextAutocompleteResponse';
import { GetIAMEquivalentReferencesResponse } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetIAMEquivalentReferencesResponse';
import { GetIAMTextSearchResponse } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetIAMTextSearchResponse';
import { GetReferenceTextSearchResponse } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetReferenceTextSearchResponse';
import { GetTextSearchResponse } from '@1po/1po-bff-fe-spec/generated/catalog/text_search/response/GetTextSearchResponse';
import { ReferenceTradingDataRequestDetail } from '@1po/1po-bff-fe-spec/generated/catalog/trading_data/request/GetReferenceTradingDataRequest';
import { GetDetailsOfLevels3Response } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/GetDetailsOfLevels3Response';
import { GetPlateDetailResponse } from '@1po/1po-bff-fe-spec/generated/catalog/tree/response/GetPlateDetailResponse';
import { GetUniversalProductsResponse } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsResponse';
import { GetUniversalProductsTreeResponse } from '@1po/1po-bff-fe-spec/generated/catalog/universal_products/response/GetUniversalProductsTreeResponse';
import { GetIAMVehicleTechnicalCriteriaResponse } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetIAMVehicleTechnicalCriteriaResponse';
import { GetVehicleOTSResponse } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetVehicleOTSResponse';
import { GetVehicleTechnicalCriteriaResponse } from '@1po/1po-bff-fe-spec/generated/catalog/vehicle/response/GetVehicleTechnicalCriteriaResponse';

import { VehicleDetail } from '@1po/1po-bff-fe-spec/generated/common/vehicle/VehicleDetail';
import { DataHubTireVinRequest } from '@1po/1po-bff-fe-spec/generated/tire/request/DataHubTireVinRequest';
import { call, put, takeEvery } from '@redux-saga/core/effects';
import { SagaIterator } from 'redux-saga';
import { v4 as uuidv4 } from 'uuid';
import { RootState } from 'app/AppStore';
import {
  addOtherReferenceRequest,
  addReferenceRequest,
  addTireRequest,
  removeSingleReferenceRequest,
} from 'domains/basket/Basket.store';
import {
  sendDataHubTireRequest,
  sendFetchOtsInformationRequest,
  sendFullTextAutocompleteRequest,
  sendGetMarketingReferencesRequest,
  sendGetPlateDetailDataRequest,
  sendGetPlateReferencesRequest,
  sendGetReferenceDetailsRequest,
  sendGetVehicleCategoriesRequest,
  sendGetVehicleCategoryImagesRequest,
  sendIAMLaborTimesRequest,
  sendIAMReferenceRequest,
  sendIAMRepairMethodsRequest,
  sendIAMRepairMethodsTreeRequest,
  sendIAMSearchBMMVehicleModelsRequest,
  sendIAMSearchBMMVehicleVersionsRequest,
  sendIAMTechnicalDataDetailsRequest,
  sendIAMTechnicalDataTreeRequest,
  sendIAMVehicleTechnicalCriteriaRequest,
  sendMaintenancePlanRequest,
  sendSearchBMMVehicleBrandsRequest,
  sendSearchBMMVehicleEnginesRequest,
  sendSearchVehicleByBMMRequest,
  sendSearchVehicleRequest,
  sendSelectVehicleRequest,
  sendTextSearchRequest,
  sendUniversalProductsRequest,
  sendUniversalProductsTreeRequest,
  sendVehicleTechnicalCriteriaRequest,
} from 'domains/catalog/Catalog.api';
import * as actions from 'domains/catalog/Catalog.store';
import {
  getLastSearchedVehicleKey,
  getLastVehicleDetail,
  getPlateGenericParts,
  getSearchStatus,
  getVehicleContext,
  resetExplodedTree,
  resetLevels3Details,
  resetMarketingReferences,
  resetPlateDetailData,
  resetPreviousTechnicalCriteria,
  resetVehicleSearchStatus,
  setExplodedIAMTree,
  setExplodedTree,
  setFoundVehiclesAreFromDifferentCountries,
  setFulltextAutocomplete,
  setFulltextSearchResult,
  setIAMFulltextEquivalentSearchResult,
  setIAMFulltextMainSearchResult,
  setIAMLaborTimeNoDataStatus,
  setIAMLaborTimes,
  setIAMRepairMethods,
  setIAMRepairMethodsNoDataStatus,
  setIAMRepairMethodsTree,
  setIAMRepairMethodsTreeNoDataStatus,
  setIAMVehicleBrands,
  setIAMVehicleModels,
  setIAMVehicleModelsNoDataStatus,
  setIAMVehicleTechnicalCriteria,
  setIAMVehicleTechnicalCriteriaNoDataStatus,
  setIAMVehicleVersions,
  setIAMVehicleVersionsNoDataStatus,
  setLastAutocompleteKey,
  setLastSearchedVehicleKey,
  setLevels3DetailNoDataStatus,
  setLevels3Details,
  setMaintenancePlanData,
  setMaintenancePlanStatus,
  setMarketingReferences,
  setMarketingReferencesForVehicleLoading,
  setMultipleVehicleFound,
  setNodeIAMReferences,
  setNodeIAMReferencesStatus,
  setOtsType,
  setOtsTypeStatus,
  setPlateDetailData,
  setPlateReferenceNoDataStatus,
  setPlateReferencesData,
  setSearchKey,
  setSearchStatus,
  setSearchVehicleLoading,
  setUniversalProducts,
  setUniversalProductsNoDataState,
  setUniversalProductsTree,
  setVehicleBrands,
  setVehicleBrandsNoDataStatus,
  setVehicleDetail,
  setVehicleEngines,
  setVehicleEnginesNoDataStatus,
  setVehicleTechnicalCriteria,
  setVehicleTechnicalCriteriaNoDataStatus,
  setVehicleTechnicalDataDetails,
  setVehicleTechnicalDataDetailsNoDataStatus,
  setVehicleTechnicalDataTree,
  setVehicleTechnicalDataTreeNoDataStatus,
} from 'domains/catalog/Catalog.store';
import { DATAHUB } from 'domains/catalog/Catalog.types';
import { resetLaborTimes } from 'domains/laborTime/LaborTime.store';
import { setPicturesLoading } from 'domains/pictures/Pictures.store';
import { fetchPricesAndStocks } from 'domains/references/References.saga';
import * as referenceActions from 'domains/references/References.store';
import {
  fetchReferencesStocksRequestSaga,
  getDHReferences,
  resetDHReferences,
  setDHReferences,
  setDHSingleReferenceStatus,
  setIAMReferences,
  setReferencesStocksNoDataStatus,
} from 'domains/references/References.store';
import { getUserContext } from 'domains/user';
import { WsResponse } from 'domains/webSockets/WebSocket.types';
import { FOUND, LOADING, NO_DATA, NOT_FOUND, sagaGuard, select } from 'utils';

export function* fetchSearchVehicleRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSearchVehicleRequestSaga>): SagaIterator {
  const { queryType, value, countryCode, includeLatestEstimate, catalogSource, versionCode } = payload;
  const userContext = yield* select(getUserContext);
  const requestId = uuidv4();
  yield put(resetPreviousTechnicalCriteria());
  yield put(resetVehicleSearchStatus({ requestId, query: value }));
  yield put(setSearchVehicleLoading({ requestId, query: value }));
  yield put(
    sendSearchVehicleRequest({
      requestId,
      value,
      queryType,
      userContext,
      countryCode,
      includeEstimate: includeLatestEstimate,
      catalogSource,
      versionCode,
    }),
  );
}

export function* handleVehicleFoundSaga(requestId: string, vehicleDetail: VehicleDetail): SagaIterator {
  const vehicle = vehicleDetail;
  yield put(resetPreviousTechnicalCriteria());
  yield put(setLastSearchedVehicleKey(vehicle.vehicleKey));
  yield put(
    setVehicleDetail({
      requestId,
      vehicle,
      status: FOUND,
    }),
  );
}

export function* fetchSearchVehicleResponseSaga(action: WsResponse<SearchVehicleResponse>): SagaIterator {
  const { vehicles, requestId, searchedCountry } = action.payload;

  if (vehiclesAreFromDifferentSearchedCountries(vehicles, searchedCountry)) {
    yield put(setFoundVehiclesAreFromDifferentCountries(true));
    yield put(setMultipleVehicleFound(action.payload));
    return;
  }

  if (vehicles.length === 1) {
    yield call(handleVehicleFoundSaga, requestId, vehicles[0]);
  } else {
    yield put(setMultipleVehicleFound(action.payload));
  }
}

function vehiclesAreFromDifferentSearchedCountries(
  vehicles: VehicleDetail[],
  searchedCountry: string | undefined,
): boolean {
  let vehicleCountry = vehicles.at(0)?.country;
  if (vehicleCountry === '') {
    vehicleCountry = undefined;
  }
  return vehicleCountry !== searchedCountry;
}

export function* fetchExplodedTreeResponseSaga(
  action: WsResponse<SearchVehiclePartCategoryTreeResponse>,
): SagaIterator {
  if (action.payload) {
    const { vehicleContext, items } = action.payload;
    const vehicleKey = vehicleContext.vehicleKey;

    const currentVehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));

    // do not refresh categories if response's ignored criteria differs with current criteria (throw away old responses)
    if (
      currentVehicleContext.ignoredCriteria &&
      vehicleContext.ignoredCriteria?.sort().join() !== [...currentVehicleContext.ignoredCriteria].sort().join()
    ) {
      return;
    }

    yield put(
      setExplodedTree({
        vehicleKey,
        items,
      }),
    );
  }
}

export function* fetchExplodedIAMTreeResponseSaga(
  action: WsResponse<SearchVehicleIAMPartCategoryTreeResponse>,
): SagaIterator {
  if (action.payload) {
    const { vehicleKey, items, provider } = action.payload;
    const lastSearchedKey = yield* select(getLastSearchedVehicleKey);

    if (lastSearchedKey && vehicleKey !== lastSearchedKey && vehicleKey.includes(lastSearchedKey)) {
      yield put(setLastSearchedVehicleKey(vehicleKey));
      yield put(setSearchKey({ searchKey: vehicleKey, vehicleKey }));
    }
    yield put(
      setExplodedIAMTree({
        vehicleKey,
        items,
        provider,
      }),
    );
  }
}

export function* fetchVehicleCategoryImagesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchVehicleCategoryImagesRequestSaga>): SagaIterator {
  const { categories } = payload;
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  if (!vehicleKey) {
    return;
  }

  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  if (categories.length > 0 && vehicleContext && userContext) {
    const imageKeys = categories.map((cat) => cat.imageKey).filter((c) => c) as string[];
    const nodeIds = categories.map((ct) => ct.nodeId);
    yield put(setPicturesLoading(imageKeys));
    yield put(
      sendGetVehicleCategoryImagesRequest({
        vehicleContext,
        userContext,
        categories: nodeIds,
      }),
    );
  }
}

export function* fetchLevels3DetailsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchLevels3DetailsRequestSaga>): SagaIterator {
  const { level3Details, vehicleKey } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(setLevels3DetailNoDataStatus({ lv3s: level3Details, vehicleKey, status: LOADING }));
  yield put(sendGetPlateDetailDataRequest({ level3Details, userContext, vehicleContext }));
}

export function* fetchLevels3DetailsResponseSaga(action: WsResponse<GetDetailsOfLevels3Response>): SagaIterator {
  if (action.payload) {
    const { level3Details, vehicleContext } = action.payload;
    yield put(setLevels3Details({ lv3s: level3Details, vehicleKey: vehicleContext.vehicleKey }));
  }
}

export function* fetchPlateDetailDataResponseSaga(action: WsResponse<GetPlateDetailResponse>): SagaIterator {
  if (action.payload) {
    const { id: plateId, thumbnailImageKey, imageKey, plateDetails, genericParts, vehicleContext } = action.payload;
    yield put(
      setPlateDetailData({
        plateId,
        thumbnailImageKey: thumbnailImageKey || NOT_FOUND,
        svgImageKey: imageKey || NOT_FOUND,
        plateDetail: plateDetails || NOT_FOUND,
        genericParts,
        vehicleKey: vehicleContext.vehicleKey,
      }),
    );
  }
}

export function* fetchPlateReferencesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchPlateReferencesRequestSaga>): SagaIterator {
  const { plateId, vehicleKey } = payload;
  const genericParts = yield* select((state: RootState) => getPlateGenericParts(state, { plateId, vehicleKey }));
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(setPlateReferenceNoDataStatus({ plateId, vehicleKey, status: LOADING }));
  yield put(sendGetPlateReferencesRequest(plateId, userContext, vehicleContext, genericParts));
}

export function* fetchPlateReferencesResponseSaga(action: WsResponse): SagaIterator {
  const { indexToReference, plateId, references, vehicleContext }: GetPlateReferencesResponse = action.payload;
  const stockRequest = references.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: ref.type,
      quantity: 1,
    };
  });
  yield put(
    referenceActions.fetchReferencesStocksRequestSaga({
      data: stockRequest,
    }),
  );
  const priceRequest = references.reduce((refs: ReferenceTradingDataRequestDetail[], ref) => {
    const refPriceRequest = {
      referenceNumber: ref.referenceNumber,
      commercialFamily: ref.familyCode ?? '',
    };
    if (ref.linkedReferences) {
      const linkedRefPriceRequests = ref.linkedReferences.map((r) => {
        return {
          referenceNumber: r.referenceNumber,
          commercialFamily: r.familyCode ?? '',
        };
      });
      return [...refs, refPriceRequest, ...linkedRefPriceRequests];
    }
    return [...refs, refPriceRequest];
  }, []);
  yield put(
    referenceActions.fetchReferencesPriceRequestSaga({
      references: priceRequest,
    }),
  );

  yield put(setPlateReferencesData({ plateId, indexToReference, vehicleKey: vehicleContext.vehicleKey }));
  yield put(
    setDHReferences({
      vehicleKey: vehicleContext.vehicleKey,
      detailStatus: LOADING,
      references,
    }),
  );
  const linked = references.flatMap((r) =>
    r.linkedReferences
      ? r.linkedReferences.flatMap((ref) => (ref && ref.referenceNumber ? [ref.referenceNumber] : []))
      : [],
  );
  const linkedRefFromStore = yield* select((state: RootState) =>
    getDHReferences(state, {
      vehicleKey: vehicleContext.vehicleKey,
      referenceNumbers: linked,
    }),
  );
  if (linkedRefFromStore.length !== linked.length) {
    //fetch missing linked refs as to be able to add them to cart together with main ref
    const missingRefs = linked.filter((r) => !linkedRefFromStore.map((l) => l.referenceNumber).includes(r));
    for (const refNum of missingRefs) {
      yield put(
        actions.fetchSingleReferenceRequestSaga({
          reference: refNum,
          vehicleKey: vehicleContext.vehicleKey,
        }),
      );
    }
  }
}

export function* fetchMarketingReferencesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchMarketingReferencesRequestSaga>): SagaIterator {
  const { nodeId, vehicleKey } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(setMarketingReferencesForVehicleLoading({ vehicleKey, nodeId }));
  yield put(sendGetMarketingReferencesRequest(nodeId, userContext, vehicleContext));
}

export function* fetchMarketingReferencesResponseSaga(
  action: WsResponse<GetMarketingReferencesResponse>,
): SagaIterator {
  const { vehicleContext, nodeId, references } = action.payload;
  const stockRequest = references.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: ref.type,
      quantity: 1,
    };
  });

  const priceRequest = references.map((r) => {
    return {
      referenceNumber: r.referenceNumber,
      commercialFamily: r.familyCode ?? '',
    };
  });

  yield call(fetchPricesAndStocks, priceRequest, stockRequest);
  yield put(
    setMarketingReferences({
      vehicleKey: vehicleContext.vehicleKey,
      nodeId,
      incomingReferences: references,
    }),
  );

  yield put(
    setDHReferences({
      vehicleKey: vehicleContext.vehicleKey,
      references,
    }),
  );
}

// BMM DH
export function* fetchSearchBMMVehicleBrandsSaga(): SagaIterator {
  yield put(setVehicleBrandsNoDataStatus(LOADING));
  const userContext = yield* select(getUserContext);

  yield put(sendSearchBMMVehicleBrandsRequest(userContext));
}

export function* fetchSearchBMMVehicleBrandsResponseSaga(
  action: WsResponse<SearchBMMVehicleBrandsResponse>,
): SagaIterator {
  const { brands } = action.payload;
  yield put(setVehicleBrands(brands));
}

export function* fetchSearchBMMVehicleEnginesSaga({
  payload,
}: ReturnType<typeof actions.fetchSearchBMMVehicleEnginesSaga>): SagaIterator {
  const { brandCode, modelCode, modelTypeCode } = payload;
  const userContext = yield* select(getUserContext);
  yield put(setVehicleEnginesNoDataStatus({ brandCode, modelCode, modelTypeCode, status: LOADING }));
  yield put(sendSearchBMMVehicleEnginesRequest(userContext, brandCode, modelCode, modelTypeCode));
}

export function* fetchSearchBMMVehicleComponentsResponseSaga(
  action: WsResponse<SearchBMMVehicleEnginesResponse>,
): SagaIterator {
  const { engines, brandCode, modelCode, modelTypeCode } = action.payload;
  yield put(setVehicleEngines({ engines, brandCode, modelCode, modelTypeCode }));
}

export function* fetchSearchVehicleByBMMRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSearchVehicleByBMMRequestSaga>): SagaIterator {
  const {
    catalogSource,
    brandCode,
    engineCode,
    modelTypeCode,
    modelCode,
    gearboxCode,
    requestId,
    iamVersionCode,
    includeLatestEstimate,
  } = payload;

  const userContext = yield* select(getUserContext);

  yield put(resetPreviousTechnicalCriteria());
  yield put(setSearchVehicleLoading({ requestId, query: requestId }));
  yield put(
    sendSearchVehicleByBMMRequest(
      userContext,
      brandCode,
      engineCode,
      modelTypeCode,
      modelCode,
      gearboxCode,
      requestId,
      iamVersionCode,
      includeLatestEstimate,
      catalogSource,
    ),
  );
}

// BMM IAM

export function* fetchIAMSearchBMMVehicleBrandsResponseSaga(
  action: WsResponse<SearchIAMBMMVehicleBrandsResponse>,
): SagaIterator {
  const { brands } = action.payload;
  yield put(setIAMVehicleBrands(brands));
}

export function* fetchIAMSearchBMMVehicleModelsSaga({
  payload,
}: ReturnType<typeof actions.fetchSearchIAMBMMVehicleModelsSaga>): SagaIterator {
  const { brandCode } = payload;
  yield put(setIAMVehicleModelsNoDataStatus({ brandCode, status: LOADING }));
  const userContext = yield* select(getUserContext);

  yield put(sendIAMSearchBMMVehicleModelsRequest(userContext, brandCode));
}

export function* fetchIAMSearchBMMVehicleModelsResponseSaga(
  action: WsResponse<SearchIAMBMMVehicleModelsResponse>,
): SagaIterator {
  const { models, brandCode } = action.payload;
  yield put(setIAMVehicleModels({ models, brandCode }));
}

export function* fetchIAMSearchBMMVehicleVersionsSaga({
  payload,
}: ReturnType<typeof actions.fetchSearchIAMBMMVehicleVersionsSaga>): SagaIterator {
  const { modelCode } = payload;
  yield put(setIAMVehicleVersionsNoDataStatus({ modelCode, status: LOADING }));
  const userContext = yield* select(getUserContext);

  yield put(sendIAMSearchBMMVehicleVersionsRequest(userContext, modelCode));
}

export function* fetchIAMSearchBMMVehicleVersionsResponseSaga(
  action: WsResponse<SearchIAMBMMVehicleVersionsResponse>,
): SagaIterator {
  const { groups, modelCode } = action.payload;
  yield put(setIAMVehicleVersions({ groups, modelCode }));
}

export function* fetchSingleReferenceRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSingleReferenceRequestSaga>): SagaIterator {
  const { reference, vehicleKey } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = vehicleKey
    ? yield* select((state: RootState) => getVehicleContext(state, vehicleKey))
    : undefined;
  yield put(setDHSingleReferenceStatus({ vehicleKey, reference, status: LOADING }));
  yield put(sendGetReferenceDetailsRequest(userContext, reference, vehicleContext));
}

export function* fetchSingleReferenceResponseSaga(action: WsResponse<GetReferenceDetailsResponse>): SagaIterator {
  const { references, vehicleContext } = action.payload;
  yield put(
    setDHReferences({
      vehicleKey: vehicleContext?.vehicleKey,
      detailStatus: FOUND,
      references,
    }),
  );
}

export function* fetchReferenceTextSearchResponseSaga(
  action: WsResponse<GetReferenceTextSearchResponse>,
): SagaIterator {
  const { references, vehicleContext, searchId, searchText } = action.payload;
  const stockRequest = references.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: ref.type,
      quantity: 1,
    };
  });

  const priceRequest = references.map((r) => {
    return {
      referenceNumber: r.referenceNumber,
      commercialFamily: r.familyCode ?? '',
    };
  });
  yield call(fetchPricesAndStocks, priceRequest, stockRequest);

  yield put(
    setDHReferences({
      vehicleKey: vehicleContext?.vehicleKey,
      detailStatus: FOUND,
      references,
    }),
  );

  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  if (!searchId || searchStatus?.searchId !== searchId) return;

  yield put(
    setSearchStatus({
      searchId,
      searchText,
      vehicleKey: vehicleContext?.vehicleKey,
      status: FOUND,
      source: DATAHUB,
      type: 'REFERENCE',
    }),
  );
}

export function* addReferenceFromCatalogToCart({
  payload,
}: ReturnType<typeof actions.addReferenceFromCatalogToCart>): SagaIterator {
  const { referenceNumber, addOtherReference, plateId, index } = payload;
  yield put(
    setReferencesStocksNoDataStatus({
      references: [referenceNumber],
      status: LOADING,
    }),
  );
  if (addOtherReference) {
    // used to add reference from IAM search result
    yield put(addOtherReferenceRequest({ reference: referenceNumber, isDH: false }));
  } else {
    yield put(addReferenceRequest({ referenceNumber, index, plateId }));
  }
}

export function* addTireFromCatalogToCart({
  payload,
}: ReturnType<typeof actions.addTireFromCatalogToCart>): SagaIterator {
  const { tire } = payload;
  yield put(
    setReferencesStocksNoDataStatus({
      references: [tire.partNumber],
      status: LOADING,
    }),
  );
  yield put(addTireRequest(tire));
}

export function* removeCartReferenceInCatalog({
  payload,
}: ReturnType<typeof actions.removeCartReferenceInCatalog>): SagaIterator {
  const {
    referenceNumber,
    vehicleKey,
    basketReferenceType,
    referenceType,
    origin,
    supplierCode,
    referenceSource,
  } = payload;
  yield put(
    removeSingleReferenceRequest({
      basketReferenceType,
      vehicleKey,
      externalBasketId: undefined,
      reference: referenceNumber,
    }),
  );
  yield put(
    fetchReferencesStocksRequestSaga({
      data: [
        {
          referenceNumber,
          type: referenceType,
          quantity: 1,
          origin: origin,
          supplierCode: supplierCode,
          referenceSource: referenceSource,
        },
      ],
      fetchEvenIfExists: true,
    }),
  );
}

export function* fetchIAMLaborTimesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMLaborTimesRequestSaga>): SagaIterator {
  const { vehicleKey, nodeId } = payload;
  const userContext = yield* select(getUserContext);
  yield put(setIAMLaborTimeNoDataStatus({ vehicleKey, nodeId, status: LOADING }));
  yield put(sendIAMLaborTimesRequest({ ...payload, userContext }));
}

export function* fetchIAMLaborTimesResponseSaga(action: WsResponse<GetIAMLaborTimesResponse>): SagaIterator {
  yield put(setIAMLaborTimes({ ...action.payload }));
}

export function* fetchFulltextAutocompleteRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchFulltextAutocompleteRequestSaga>): SagaIterator {
  const { vehicleKey, searchText } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  yield put(sendFullTextAutocompleteRequest(userContext, vehicleContext, searchText));
  yield put(setLastAutocompleteKey({ vehicleKey, searchText }));
}

export function* fetchFulltextAutocompleteResponseSaga(
  action: WsResponse<GetFullTextAutocompleteResponse>,
): SagaIterator {
  const { fullTexts, vehicleContext, searchText } = action.payload;
  yield put(setFulltextAutocomplete({ vehicleKey: vehicleContext.vehicleKey, searchText, fullTexts }));
}

export function* fetchTextSearchRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchTextSearchRequestSaga>): SagaIterator {
  const { vehicleKey, searchText, queryType, searchId, catalogSource } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleContext = vehicleKey
    ? yield* select((state: RootState) => getVehicleContext(state, vehicleKey))
    : undefined;
  yield put(sendTextSearchRequest(searchId, userContext, vehicleContext, searchText, queryType, catalogSource));

  yield put(
    setSearchStatus({
      searchId,
      searchText,
      vehicleKey,
      status: LOADING,
      source: undefined,
      type: undefined,
    }),
  );
}

export function* fetchFullTextSearchResponseSaga(action: WsResponse<GetTextSearchResponse>): SagaIterator {
  const { references, referenceNumbers, vehicleContext, searchText, searchId } = action.payload;
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  if (searchStatus?.searchId !== searchId) return;

  yield put(
    setSearchStatus({
      searchId,
      searchText,
      vehicleKey: vehicleContext.vehicleKey,
      status: FOUND,
      source: DATAHUB,
      type: 'FULLTEXT',
    }),
  );

  yield put(
    setFulltextSearchResult({
      refNumbers: referenceNumbers,
    }),
  );
  yield put(
    setDHReferences({
      vehicleKey: vehicleContext.vehicleKey,
      references,
    }),
  );
}

export function* fetchIAMFullTextSearchResponseSaga(action: WsResponse<GetIAMTextSearchResponse>): SagaIterator {
  const {
    referenceGroups,
    provider,
    vehicleContext,
    searchText,
    searchId,
    substituteTecdocResult,
    equivalentReferencesToBePushed,
  } = action.payload;
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  if (searchStatus?.searchId !== searchId) return;

  const fullTexts = referenceGroups.map((group) => {
    return {
      groupName: group.groupName,
      mainReferences: group.mainReferences.map((ref) => ref.referenceNumber),
      additionalEquivalencesLoading: equivalentReferencesToBePushed,
      equivalentReferences:
        provider === 'TECDOC' && substituteTecdocResult
          ? group.equivalentReferences.map((ref) => ref.referenceNumber)
          : (LOADING as NO_DATA),
    };
  });

  yield put(setIAMFulltextMainSearchResult({ fullTexts }));
  yield put(
    setSearchStatus({
      searchId,
      searchText,
      vehicleKey: vehicleContext?.vehicleKey,
      status: FOUND,
      source: 'IAM',
      type: 'FULLTEXT',
    }),
  );

  const references = referenceGroups.flatMap((group) => group.mainReferences);
  const equivalentReferences = referenceGroups.flatMap((group) => group.equivalentReferences);
  const allReferences = [...references, ...equivalentReferences];

  const stockRequest = allReferences.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: ref.type,
      quantity: 1,
      origin: ref.origin,
      supplierCode: ref.supplierCode,
      referenceSource: ref.referenceSource,
    };
  });

  const priceRequest = allReferences.map((r) => {
    return {
      referenceNumber: r.referenceNumber,
      commercialFamily: r.commercialFamily ?? '',
      supplierCode: r.supplierCode,
      origin: r.origin,
      referenceSource: r.referenceSource,
    };
  });
  yield call(fetchPricesAndStocks, priceRequest, stockRequest);

  yield put(setIAMReferences({ vehicleKey: undefined, references, detailStatus: FOUND }));
  yield put(
    setIAMReferences({
      vehicleKey: undefined,
      references: equivalentReferences,
      detailStatus: FOUND,
    }),
  );
}

export function* fetchIAMEquivalentReferencesResponseSaga(
  action: WsResponse<GetIAMEquivalentReferencesResponse>,
): SagaIterator {
  const { mainToEquivalentReferences, searchId } = action.payload;
  const searchStatus = yield* select((state: RootState) => getSearchStatus(state));
  if (searchStatus?.searchId !== searchId) return;

  yield put(setIAMFulltextEquivalentSearchResult({ mainToEquivalentReferences }));
  const references = mainToEquivalentReferences.flatMap((group) => group.equivalentReferences);

  yield put(setIAMReferences({ vehicleKey: undefined, references, detailStatus: FOUND }));
}

export function* fetchUniversalProductsTreeRequestSaga(): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(setUniversalProductsTree(LOADING));
  yield put(sendUniversalProductsTreeRequest(userContext));
}

export function* fetchUniversalProductsTreeResponseSaga(
  action: WsResponse<GetUniversalProductsTreeResponse>,
): SagaIterator {
  const { categoryTree } = action.payload;
  yield put(setUniversalProductsTree(categoryTree));
}

export function* fetchUniversalProductsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchUniversalProductsRequestSaga>): SagaIterator {
  const userContext = yield* select(getUserContext);
  yield put(setUniversalProductsNoDataState({ referenceNumbers: payload, status: LOADING }));
  yield put(sendUniversalProductsRequest(userContext, payload));
}

export function* fetchUniversalProductsResponseSaga(action: WsResponse<GetUniversalProductsResponse>): SagaIterator {
  const { universalProducts } = action.payload;
  yield put(setUniversalProducts(universalProducts));
}

export function* fetchVehicleTechnicalCriteriaRequestSaga(): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  const userContext = yield* select(getUserContext);
  if (!vehicleKey) return;
  yield put(sendVehicleTechnicalCriteriaRequest(vehicleKey, userContext));
  yield put(setVehicleTechnicalCriteriaNoDataStatus({ vehicleKey, status: LOADING }));
}

export function* fetchVehicleTechnicalCriteriaResponseSaga(
  action: WsResponse<GetVehicleTechnicalCriteriaResponse>,
): SagaIterator {
  const { vehicleTechnicalCriteria } = action.payload;
  const { vehicleKey } = vehicleTechnicalCriteria;
  if (!vehicleKey) return;
  yield put(setVehicleTechnicalCriteria({ vehicleKey, vehicleTechnicalCriteria }));
}

export function* fetchIAMVehicleTechnicalCriteriaRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  const vehicleKey = vehicleDetail?.vehicleKey;
  const versionCode = vehicleDetail?.iamVehicle?.versionCode;
  const vehicleCountryCode = vehicleDetail?.country ?? userContext.r1Country;
  if (vehicleKey === undefined || versionCode === undefined || vehicleCountryCode === undefined) return;
  const vehicleContext = { vehicleKey, versionCode, vehicleCountryCode };
  yield put(sendIAMVehicleTechnicalCriteriaRequest(vehicleContext, userContext));
  yield put(setIAMVehicleTechnicalCriteriaNoDataStatus({ vehicleKey, status: LOADING }));
}

export function* fetchIAMVehicleTechnicalCriteriaResponseSaga(
  action: WsResponse<GetIAMVehicleTechnicalCriteriaResponse>,
): SagaIterator {
  yield put(setIAMVehicleTechnicalCriteria(action.payload));
}

export function* fetchIAMReferencesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMReferencesRequestSaga>): SagaIterator {
  const userContext = yield* select(getUserContext);
  const { versionCode, vehicleKey, nodeId } = payload;
  yield put(setNodeIAMReferencesStatus({ nodeId, vehicleKey, loadingState: LOADING }));
  yield put(sendIAMReferenceRequest(versionCode, vehicleKey, userContext, nodeId));
}

export function* fetchIAMReferencesResponseSaga(action: WsResponse): SagaIterator {
  const {
    versionCode,
    vehicleKey,
    nodeId,
    references,
    textFacets,
    rangeFacets,
  }: GetIAMReferencesResponse = action.payload;
  yield put(
    setNodeIAMReferences({
      versionCode,
      vehicleKey,
      nodeId,
      incomingReferences: references,
      textFilters: textFacets,
      rangeFilters: rangeFacets,
    }),
  );

  const stockRequest = references.map((ref) => {
    return {
      referenceNumber: ref.referenceNumber,
      type: ref.type,
      quantity: 1,
      supplierCode: ref.supplierCode,
      origin: ref.origin,
      referenceSource: ref.referenceSource,
    };
  });

  const priceRequest = references.map((r) => {
    return {
      referenceNumber: r.referenceNumber,
      commercialFamily: r.commercialFamily ?? '',
      supplierCode: r.supplierCode,
      origin: r.origin,
      referenceSource: r.referenceSource,
    };
  });
  yield call(fetchPricesAndStocks, priceRequest, stockRequest);
  yield put(setIAMReferences({ vehicleKey, references, detailStatus: LOADING }));
}

export function* fetchOtsInformationRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchOtsInformationRequestSaga>): SagaIterator {
  const { vin } = payload;
  const userContext = yield* select(getUserContext);
  yield put(setOtsTypeStatus({ vehicleKey: vin, status: LOADING }));
  yield put(sendFetchOtsInformationRequest({ vin, userContext }));
}

export function* fetchOtsInformationResponseSaga(action: WsResponse<GetVehicleOTSResponse>): SagaIterator {
  const { vin, otsType } = action.payload;
  yield put(setOtsType({ vehicleKey: vin, otsType }));
}

export function* fetchSwitchVehicleRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchSwitchVehicleRequestSaga>): SagaIterator {
  const { requestId, vehicleDetail } = payload;

  const userContext = yield* select(getUserContext);

  yield call(handleVehicleFoundSaga, requestId, vehicleDetail);
  yield put(setMultipleVehicleFound(undefined));
  yield put(setFoundVehiclesAreFromDifferentCountries(undefined));
  yield put(
    sendSelectVehicleRequest({
      vehicleDetail,
      userContext,
      requestId,
    }),
  );
  yield put(actions.fetchDHTireRequestSaga(vehicleDetail));
}

export function* fetchDHTireRequestSaga({ payload }: ReturnType<typeof actions.fetchDHTireRequestSaga>): SagaIterator {
  const vehicleDetail = payload;
  const userContext = yield* select(getUserContext);

  if (vehicleDetail.catalogSource === DATAHUB && vehicleDetail.vin !== undefined) {
    const tireRequest: DataHubTireVinRequest = { userContext, vin: vehicleDetail.vin };
    yield put(sendDataHubTireRequest(tireRequest));
  }
}

export function* fetchGetVehicleCategoriesRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchGetVehicleCategoriesRequestSaga>): SagaIterator {
  const { vehicleKey } = payload;

  if (!vehicleKey) return;

  const vehicleContext = yield* select((state: RootState) => getVehicleContext(state, vehicleKey));
  const userContext = yield* select(getUserContext);

  yield put(resetExplodedTree({ vehicleKey }));
  yield put(resetLevels3Details({ vehicleKey }));
  yield put(resetPlateDetailData({ vehicleKey }));
  yield put(resetDHReferences({ vehicleKey }));
  yield put(resetMarketingReferences({ vehicleKey }));
  yield put(resetLaborTimes({ vehicleKey }));

  yield put(
    sendGetVehicleCategoriesRequest({
      vehicleContext,
      userContext,
    }),
  );
}

export function* searchIAMBMMVehicleSaga({
  payload,
}: ReturnType<typeof actions.searchIAMBMMVehicleSaga>): SagaIterator {
  const { brandCode, modelCode, versionCode, requestId } = payload;

  yield put(
    actions.fetchSearchVehicleByBMMRequestSaga({
      catalogSource: 'IAM',
      brandCode,
      engineCode: '',
      modelTypeCode: '',
      modelCode,
      gearboxCode: '',
      requestId,
      iamVersionCode: versionCode,
      includeLatestEstimate: true,
    }),
  );
}

export function* fetchIAMTechnicalDataTreeRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  const vehicleCountryCode = vehicleDetail?.country ?? userContext.r1Country;

  if (vehicleDetail?.iamVehicle?.versionCode === undefined || vehicleCountryCode === undefined) {
    return;
  }

  const request: GetIAMTechnicalDataTreeRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode,
    },
    userContext,
  };
  yield put(sendIAMTechnicalDataTreeRequest(request));
  yield put(
    setVehicleTechnicalDataTreeNoDataStatus({
      vehicleKey: vehicleDetail.vehicleKey,
      vehicleCode: vehicleDetail.iamVehicle.versionCode,
      status: LOADING,
    }),
  );
}

export function* fetchIAMTechnicalDataTreeResponseSaga(
  action: WsResponse<GetIAMTechnicalDataTreeResponse>,
): SagaIterator {
  const { vehicleKey, vehicleCode, l1List } = action.payload;
  yield put(setVehicleTechnicalDataTree({ vehicleKey, vehicleCode, l1List }));
}

export function* fetchIAMTechnicalDataDetailsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMTechnicalDataDetailsRequestSaga>): SagaIterator {
  const { categoryIds } = payload;
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  const vehicleCountryCode = vehicleDetail?.country ?? userContext.r1Country;
  if (vehicleDetail?.iamVehicle?.versionCode === undefined || vehicleCountryCode === undefined) {
    return;
  }

  const request: GetIAMTechnicalDataDetailsRequest = {
    vehicleContext: {
      vehicleKey: vehicleDetail.vehicleKey,
      versionCode: vehicleDetail.iamVehicle.versionCode,
      vehicleCountryCode,
    },
    userContext,
    categoryIds,
  };
  yield put(
    setVehicleTechnicalDataDetailsNoDataStatus({
      vehicleKey: vehicleDetail.vehicleKey,
      vehicleCode: vehicleDetail.iamVehicle.versionCode,
      l3Ids: categoryIds,
      status: LOADING,
    }),
  );
  yield put(sendIAMTechnicalDataDetailsRequest(request));
}

export function* fetchIAMTechnicalDataDetailsResponseSaga(
  action: WsResponse<GetIAMTechnicalDataDetailResponse>,
): SagaIterator {
  const { vehicleKey, vehicleCode, l3Id, contentList } = action.payload;
  yield put(setVehicleTechnicalDataDetails({ vehicleKey, vehicleCode, l3Id, contentList }));
}

export function* fetchMaintenancePlanRequestSaga(): SagaIterator {
  const vehicleDetail = yield* select(getLastVehicleDetail);
  const userContext = yield* select(getUserContext);
  if (!vehicleDetail) {
    return;
  }
  const vehicleKey = vehicleDetail.vehicleKey;
  if (vehicleDetail?.vin === undefined) {
    yield put(setMaintenancePlanStatus({ vehicleKey, status: NOT_FOUND }));
    return;
  }
  const request: GetMaintenancePlanRequest = {
    vin: vehicleKey,
    userContext,
  };
  yield put(setMaintenancePlanStatus({ vehicleKey, status: LOADING }));
  yield put(sendMaintenancePlanRequest(request));
}

export function* fetchMaintenancePlanResponseSaga(action: WsResponse<GetMaintenancePlanResponse>): SagaIterator {
  const vehicleKey = yield* select(getLastSearchedVehicleKey);
  const {
    brakeFluid,
    coolantFluid,
    engineOilList,
    headerTranslationsList,
    operationByMileageOrTimeList,
    operationByServiceList,
    specialInformationList,
  } = action.payload;
  const allData = [
    brakeFluid,
    coolantFluid,
    engineOilList,
    headerTranslationsList,
    operationByMileageOrTimeList,
    operationByServiceList,
    specialInformationList,
  ];
  if (allData.every((d) => d.length === 0) && vehicleKey) {
    yield put(setMaintenancePlanStatus({ vehicleKey, status: NOT_FOUND }));
    return;
  }
  yield put(setMaintenancePlanData(action.payload));
}

//
export function* fetchIAMRepairMethodsRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMRepairMethodsRequestSaga>): SagaIterator {
  const { vehicleKey, nodeId, versionCode } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  if (!vehicleDetail) {
    return;
  }
  if (vehicleDetail?.iamVehicle?.versionCode === undefined || vehicleDetail?.iamVehicle?.versionCode !== versionCode) {
    return;
  }
  const vehicleContext = {
    vehicleKey: vehicleDetail.vehicleKey,
    versionCode: vehicleDetail.iamVehicle.versionCode,
    vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
  };

  yield put(setIAMRepairMethodsNoDataStatus({ vehicleKey, nodeId, status: LOADING }));
  yield put(sendIAMRepairMethodsRequest({ vehicleContext, userContext, nodeId }));
}

export function* fetchIAMRepairMethodsTreeRequestSaga({
  payload,
}: ReturnType<typeof actions.fetchIAMRepairMethodsTreeRequestSaga>): SagaIterator {
  const { vehicleKey, versionCode } = payload;
  const userContext = yield* select(getUserContext);
  const vehicleDetail = yield* select(getLastVehicleDetail);
  if (!vehicleDetail) {
    return;
  }
  if (vehicleDetail?.iamVehicle?.versionCode === undefined || vehicleDetail?.iamVehicle?.versionCode !== versionCode) {
    return;
  }
  const vehicleContext = {
    vehicleKey: vehicleDetail.vehicleKey,
    versionCode: vehicleDetail.iamVehicle.versionCode,
    vehicleCountryCode: vehicleDetail?.country ?? userContext.r1Country,
  };

  yield put(setIAMRepairMethodsTreeNoDataStatus({ vehicleKey, status: LOADING }));
  yield put(sendIAMRepairMethodsTreeRequest({ vehicleContext, userContext }));
}

export function* fetchIAMRepairMethodsResponseSaga(action: WsResponse<GetIAMRepairMethodsResponse>): SagaIterator {
  const { nodeId, vehicleContext, repairMethods } = action.payload;
  yield put(setIAMRepairMethods({ vehicleKey: vehicleContext.vehicleKey, nodeId, repairMethods }));
}

export function* fetchIAMRepairMethodsTreeResponseSaga(
  action: WsResponse<GetIAMRepairMethodsTreeResponse>,
): SagaIterator {
  const { vehicleContext, repairMethods } = action.payload;
  yield put(setIAMRepairMethodsTree({ vehicleKey: vehicleContext.vehicleKey, repairMethods }));
}

export function* CatalogSagas(): SagaIterator {
  yield takeEvery(actions.fetchSearchVehicleRequestSaga.type, sagaGuard(fetchSearchVehicleRequestSaga));
  yield takeEvery(actions.fetchSearchVehicleResponseSaga.type, sagaGuard(fetchSearchVehicleResponseSaga));
  yield takeEvery(actions.fetchExplodedTreeResponseSaga.type, sagaGuard(fetchExplodedTreeResponseSaga));
  yield takeEvery(actions.fetchExplodedIAMTreeResponseSaga.type, sagaGuard(fetchExplodedIAMTreeResponseSaga));
  yield takeEvery(actions.fetchVehicleCategoryImagesRequestSaga.type, sagaGuard(fetchVehicleCategoryImagesRequestSaga));
  yield takeEvery(actions.fetchLevels3DetailsRequestSaga.type, sagaGuard(fetchLevels3DetailsRequestSaga));
  yield takeEvery(actions.fetchPlateDetailDataResponseSaga.type, sagaGuard(fetchPlateDetailDataResponseSaga));
  yield takeEvery(actions.fetchLevels3DetailsResponseSaga.type, sagaGuard(fetchLevels3DetailsResponseSaga));
  yield takeEvery(actions.fetchPlateReferencesRequestSaga.type, sagaGuard(fetchPlateReferencesRequestSaga));
  yield takeEvery(actions.fetchPlateReferencesResponseSaga.type, sagaGuard(fetchPlateReferencesResponseSaga));
  yield takeEvery(actions.fetchMarketingReferencesRequestSaga.type, sagaGuard(fetchMarketingReferencesRequestSaga));
  yield takeEvery(actions.fetchMarketingReferencesResponseSaga.type, sagaGuard(fetchMarketingReferencesResponseSaga));
  yield takeEvery(actions.fetchSearchBMMVehicleBrandsSaga.type, sagaGuard(fetchSearchBMMVehicleBrandsSaga));
  yield takeEvery(
    actions.fetchSearchBMMVehicleBrandsResponseSaga.type,
    sagaGuard(fetchSearchBMMVehicleBrandsResponseSaga),
  );
  yield takeEvery(actions.fetchSearchBMMVehicleEnginesSaga.type, sagaGuard(fetchSearchBMMVehicleEnginesSaga));
  yield takeEvery(
    actions.fetchSearchBMMVehicleEnginesResponseSaga.type,
    sagaGuard(fetchSearchBMMVehicleComponentsResponseSaga),
  );
  yield takeEvery(actions.fetchSearchVehicleByBMMRequestSaga.type, sagaGuard(fetchSearchVehicleByBMMRequestSaga));
  yield takeEvery(
    actions.fetchSearchIAMBMMVehicleBrandsResponseSaga.type,
    sagaGuard(fetchIAMSearchBMMVehicleBrandsResponseSaga),
  );
  yield takeEvery(actions.fetchSearchIAMBMMVehicleModelsSaga.type, sagaGuard(fetchIAMSearchBMMVehicleModelsSaga));
  yield takeEvery(
    actions.fetchSearchIAMBMMVehicleModelsResponseSaga.type,
    sagaGuard(fetchIAMSearchBMMVehicleModelsResponseSaga),
  );
  yield takeEvery(actions.fetchSearchIAMBMMVehicleVersionsSaga.type, sagaGuard(fetchIAMSearchBMMVehicleVersionsSaga));
  yield takeEvery(
    actions.fetchSearchIAMBMMVehicleVersionsResponseSaga.type,
    sagaGuard(fetchIAMSearchBMMVehicleVersionsResponseSaga),
  );
  yield takeEvery(actions.fetchSingleReferenceRequestSaga.type, sagaGuard(fetchSingleReferenceRequestSaga));
  yield takeEvery(actions.fetchSingleReferenceResponseSaga.type, sagaGuard(fetchSingleReferenceResponseSaga));
  yield takeEvery(actions.fetchReferenceTextSearchResponseSaga.type, sagaGuard(fetchReferenceTextSearchResponseSaga));
  yield takeEvery(actions.addReferenceFromCatalogToCart.type, sagaGuard(addReferenceFromCatalogToCart));
  yield takeEvery(actions.addTireFromCatalogToCart.type, sagaGuard(addTireFromCatalogToCart));
  yield takeEvery(actions.removeCartReferenceInCatalog.type, sagaGuard(removeCartReferenceInCatalog));
  yield takeEvery(actions.fetchIAMLaborTimesRequestSaga.type, sagaGuard(fetchIAMLaborTimesRequestSaga));
  yield takeEvery(actions.fetchIAMLaborTimesResponseSaga.type, sagaGuard(fetchIAMLaborTimesResponseSaga));
  yield takeEvery(actions.fetchFulltextAutocompleteRequestSaga.type, sagaGuard(fetchFulltextAutocompleteRequestSaga));
  yield takeEvery(actions.fetchFulltextAutocompleteResponseSaga.type, sagaGuard(fetchFulltextAutocompleteResponseSaga));
  yield takeEvery(actions.fetchTextSearchRequestSaga.type, sagaGuard(fetchTextSearchRequestSaga));
  yield takeEvery(actions.fetchFullTextSearchResponseSaga.type, sagaGuard(fetchFullTextSearchResponseSaga));
  yield takeEvery(actions.fetchIAMFullTextSearchResponseSaga.type, sagaGuard(fetchIAMFullTextSearchResponseSaga));
  yield takeEvery(
    actions.fetchIAMEquivalentReferencesResponseSaga.type,
    sagaGuard(fetchIAMEquivalentReferencesResponseSaga),
  );
  yield takeEvery(actions.fetchUniversalProductsTreeRequestSaga.type, sagaGuard(fetchUniversalProductsTreeRequestSaga));
  yield takeEvery(
    actions.fetchUniversalProductsTreeResponseSaga.type,
    sagaGuard(fetchUniversalProductsTreeResponseSaga),
  );
  yield takeEvery(actions.fetchUniversalProductsRequestSaga.type, sagaGuard(fetchUniversalProductsRequestSaga));
  yield takeEvery(actions.fetchUniversalProductsResponseSaga.type, sagaGuard(fetchUniversalProductsResponseSaga));
  yield takeEvery(
    actions.fetchVehicleTechnicalCriteriaRequestSaga.type,
    sagaGuard(fetchVehicleTechnicalCriteriaRequestSaga),
  );
  yield takeEvery(
    actions.fetchVehicleTechnicalCriteriaResponseSaga.type,
    sagaGuard(fetchVehicleTechnicalCriteriaResponseSaga),
  );
  yield takeEvery(
    actions.fetchIAMVehicleTechnicalCriteriaRequestSaga.type,
    sagaGuard(fetchIAMVehicleTechnicalCriteriaRequestSaga),
  );
  yield takeEvery(
    actions.fetchIAMVehicleTechnicalCriteriaResponseSaga.type,
    sagaGuard(fetchIAMVehicleTechnicalCriteriaResponseSaga),
  );
  yield takeEvery(actions.fetchIAMReferencesRequestSaga.type, sagaGuard(fetchIAMReferencesRequestSaga));
  yield takeEvery(actions.fetchOtsInformationRequestSaga.type, sagaGuard(fetchOtsInformationRequestSaga));
  yield takeEvery(actions.fetchOtsInformationResponseSaga.type, sagaGuard(fetchOtsInformationResponseSaga));
  yield takeEvery(actions.fetchIAMReferencesResponseSaga.type, sagaGuard(fetchIAMReferencesResponseSaga));
  yield takeEvery(actions.fetchSwitchVehicleRequestSaga.type, sagaGuard(fetchSwitchVehicleRequestSaga));
  yield takeEvery(actions.fetchGetVehicleCategoriesRequestSaga.type, sagaGuard(fetchGetVehicleCategoriesRequestSaga));
  yield takeEvery(actions.searchIAMBMMVehicleSaga.type, sagaGuard(searchIAMBMMVehicleSaga));
  yield takeEvery(actions.fetchIAMTechnicalDataTreeRequestSaga.type, sagaGuard(fetchIAMTechnicalDataTreeRequestSaga));
  yield takeEvery(
    actions.fetchIAMTechnicalDataDetailsRequestSaga.type,
    sagaGuard(fetchIAMTechnicalDataDetailsRequestSaga),
  );
  yield takeEvery(actions.fetchIAMTechnicalDataTreeResponseSaga.type, sagaGuard(fetchIAMTechnicalDataTreeResponseSaga));
  yield takeEvery(
    actions.fetchIAMTechnicalDataDetailsResponseSaga.type,
    sagaGuard(fetchIAMTechnicalDataDetailsResponseSaga),
  );
  yield takeEvery(actions.fetchMaintenancePlanRequestSaga.type, sagaGuard(fetchMaintenancePlanRequestSaga));
  yield takeEvery(actions.fetchMaintenancePlanResponseSaga.type, sagaGuard(fetchMaintenancePlanResponseSaga));
  yield takeEvery(actions.fetchDHTireRequestSaga.type, sagaGuard(fetchDHTireRequestSaga));

  yield takeEvery(actions.fetchIAMRepairMethodsRequestSaga.type, sagaGuard(fetchIAMRepairMethodsRequestSaga));
  yield takeEvery(actions.fetchIAMRepairMethodsResponseSaga.type, sagaGuard(fetchIAMRepairMethodsResponseSaga));

  yield takeEvery(actions.fetchIAMRepairMethodsTreeRequestSaga.type, sagaGuard(fetchIAMRepairMethodsTreeRequestSaga));
  yield takeEvery(actions.fetchIAMRepairMethodsTreeResponseSaga.type, sagaGuard(fetchIAMRepairMethodsTreeResponseSaga));
}
