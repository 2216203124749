import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DownOutlinedIcon } from 'assets/icons';
import { getTireSetParam, setTireSetParam } from 'domains/tires/Tire.store';
import { TireSet } from 'domains/tires/Tire.types';
import {
  BrandMenuItem,
  DropdownMenu,
  SAntDropdown,
} from 'pages/TiresPage/TireSearch/TiresSearchbar/TiresSearchbar.styled';
import { Checkbox, Flex, Icon, MarginBox, Text } from 'UI';

const TireSetSelection = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const tireSet = useSelector(getTireSetParam);

  const getLabel = () => {
    const setString = tireSet ? `(${tireSet})` : '';
    return <>{t('catalog.tires.set_param', 'Set {{set}}', { set: setString })}</>;
  };

  const setArray: TireSet[] = [2, 4, 6, 8];

  const tireSetMenu = (
    <DropdownMenu type={'season'} translateY={0} translateX={0}>
      <Flex direction={'column'}>
        {setArray.map((setNum) => {
          return (
            <Flex key={setNum} direction={'row'} align={'center'}>
              <MarginBox mr={10}>
                <Checkbox
                  checked={tireSet === setNum}
                  onChange={() => dispatch(setTireSetParam(tireSet === setNum ? undefined : setNum))}
                />
              </MarginBox>
              <BrandMenuItem onClick={() => dispatch(setTireSetParam(tireSet === setNum ? undefined : setNum))}>
                <Text type={'light_14_black_65'}>{`Set ${setNum}`}</Text>
              </BrandMenuItem>
            </Flex>
          );
        })}
      </Flex>
    </DropdownMenu>
  );

  return (
    <SAntDropdown
      overlay={tireSetMenu}
      trigger={['click']}
      visible={open}
      onVisibleChange={(visible) => setOpen(visible)}
      bordered
    >
      <a className="ant-dropdown-link">
        <MarginBox mr={12} />
        <Text type={'lead_dim'}>{getLabel()}</Text>
        <Flex direction={'row-reverse'}>
          <Icon IconComponent={DownOutlinedIcon} size={18} color={'black'} />
        </Flex>
        <MarginBox mx={5} />
      </a>
    </SAntDropdown>
  );
};

export default TireSetSelection;
