import React, { PropsWithChildren, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { TireSeasonSelection } from 'components/TireSeasonSelection';
import { toSearchParams } from 'domains/tires/Tire.mapper';
import {
  getTireSearchDimensions,
  getTireSearchInputDimensions,
  getTireSearchQuery,
  resetTireFilters,
  setSearchParams,
  setTireSearchInputDimensions,
  setTireSearchQuery,
  tireProductsSearchRequestSaga,
} from 'domains/tires/Tire.store';
import { TireBrandSelection } from 'pages/TiresPage/TireSearch/TireBrandSelection';
import { TireDimensionsMenuItem } from 'pages/TiresPage/TireSearch/TireDimensionsSearchBar/TireDimensionsMenuItem';
import { TireSearchProps } from 'pages/TiresPage/TireSearch/TireSearch';
import TireSetSelection from 'pages/TiresPage/TireSearch/TireSetSelection';
import { BlackButton, Flex, MarginBox, Text } from 'UI';

interface TireDimensionsItemWrapper extends PropsWithChildren {
  label?: string;
}

const TireDimensionsItemWrapper = ({ label, children }: TireDimensionsItemWrapper) => (
  <Flex direction={'column'} minWidth={120} maxWidth={120}>
    <MarginBox mx={5} mt={15}>
      {label && (
        <>
          <Text type={'light_dimmer'}>{label}</Text>
          <MarginBox mt={5} />
        </>
      )}
      {children}
    </MarginBox>
  </Flex>
);
const TireDimensionsSecondItemWrapper = ({ label, children }: TireDimensionsItemWrapper) => (
  <Flex direction={'column'} minWidth={222} maxWidth={222}>
    <MarginBox mx={5} mt={15}>
      {label && (
        <>
          <Text type={'light_dimmer'}>{label}</Text>
          <MarginBox mt={5} />
        </>
      )}
      {children}
    </MarginBox>
  </Flex>
);

function getDimensionsSearchQuery(
  width: string,
  series: string,
  diameter: string,
  speedIndicator?: string,
  chargeIndicator?: string,
  delimiter = ' ',
): string {
  const baseParams = `${width}${delimiter}${series}${delimiter}${diameter}`;
  return (
    baseParams +
    `${delimiter}${chargeIndicator && chargeIndicator.length > 0 ? chargeIndicator : ''}` +
    `${delimiter}${speedIndicator && speedIndicator.length > 0 ? speedIndicator : ''}`
  );
}

type TireDimensionsSearchBarProps = Omit<TireSearchProps, 'setQuery'>;

export const TireDimensionsSearchBar = ({ brands, setBrands, season, setSeason }: TireDimensionsSearchBarProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const tireSearchDimensions = useSelector(getTireSearchDimensions);
  const [menuOpen, setMenuOpen] = useState(false);
  const query = useSelector(getTireSearchQuery);
  const brandIds = brands.map((brand) => brand.brandId);
  const { width, series, diameter, speedIndicator, chargeIndicator } = toSearchParams(query, season, brandIds);

  useEffect(() => {
    dispatch(
      setTireSearchInputDimensions({
        width,
        series,
        diameter,
        speedIndicator,
        chargeIndicator,
      }),
    );
  }, [width, series, diameter, speedIndicator, chargeIndicator, dispatch]);

  const tireSearchInputDimensions = useSelector(getTireSearchInputDimensions);

  const handleSearch = () => {
    const selectedBrands = brands.filter((b) => b.selected);
    if (
      selectedBrands &&
      tireSearchInputDimensions &&
      tireSearchInputDimensions.width &&
      tireSearchInputDimensions.series &&
      tireSearchInputDimensions.diameter
    ) {
      const queryValue = getDimensionsSearchQuery(
        tireSearchInputDimensions.width,
        tireSearchInputDimensions.series,
        tireSearchInputDimensions.diameter,
        tireSearchInputDimensions.speedIndicator,
        tireSearchInputDimensions.chargeIndicator,
      );
      dispatch(resetTireFilters());
      dispatch(
        tireProductsSearchRequestSaga({
          query: queryValue,
          season,
          brands: selectedBrands.map((brand) => brand.brandId),
        }),
      );
      dispatch(
        setSearchParams({
          searchParamsBase64: toSearchParams(
            queryValue,
            season,
            selectedBrands.map((brand) => brand.brandId),
          ).searchParamsBase64,
        }),
      );
      dispatch(setTireSearchQuery({ query: queryValue }));
    }
  };

  const menuItems: {
    id: string;
    label: string;
    initialValue?: string;
    onValueChange: (newValue: string | undefined) => void;
    items: string[];
  }[] = [
    {
      id: 'tire-search-criteria-width',
      initialValue: tireSearchInputDimensions?.width,
      label: t('catalog.tires.search.criteria.width', 'Width'),
      onValueChange: (newWidth) => {
        dispatch(
          setTireSearchInputDimensions({
            ...tireSearchInputDimensions,
            width: newWidth,
          }),
        );
      },
      items: tireSearchDimensions?.width ?? [],
    },
    {
      id: 'tire-search-criteria-series',
      initialValue: tireSearchInputDimensions?.series,
      label: t('catalog.tires.search.criteria.ratio', 'Ratio'),
      onValueChange: (newSeries) => {
        dispatch(
          setTireSearchInputDimensions({
            ...tireSearchInputDimensions,
            series: newSeries,
          }),
        );
      },
      items: tireSearchDimensions?.series ?? [],
    },
    {
      id: 'tire-search-criteria-diameter',
      initialValue: tireSearchInputDimensions?.diameter,
      label: t('catalog.tires.search.criteria.diameter', 'Diameter'),
      onValueChange: (newDiameter) => {
        dispatch(
          setTireSearchInputDimensions({
            ...tireSearchInputDimensions,
            diameter: newDiameter,
          }),
        );
      },
      items: tireSearchDimensions?.diameter ?? [],
    },
    {
      id: 'tire-search-criteria-chargeIndicator',
      initialValue: tireSearchInputDimensions?.chargeIndicator,
      label: t('catalog.tires.search.criteria.load_index', 'Load index'),
      onValueChange: (newCharge) => {
        dispatch(
          setTireSearchInputDimensions({
            ...tireSearchInputDimensions,
            chargeIndicator: newCharge,
          }),
        );
      },
      items: tireSearchDimensions?.chargeIndicator ?? [],
    },
    {
      id: 'tire-search-criteria-speedIndicator',
      initialValue: tireSearchInputDimensions?.speedIndicator,
      label: t('catalog.tires.search.criteria.speed_index', 'Speed index'),
      onValueChange: (newSpeed) => {
        dispatch(
          setTireSearchInputDimensions({
            ...tireSearchInputDimensions,
            speedIndicator: newSpeed,
          }),
        );
      },
      items: tireSearchDimensions?.speedIndicator ?? [],
    },
  ];

  return (
    <MarginBox mx={10} my={15}>
      <Flex direction={'column'} align={'flex-start'} justify={'center'}>
        <Flex gap={30}>
          {menuItems.map((menuItem) => (
            <TireDimensionsItemWrapper label={menuItem.label} key={menuItem.id}>
              <TireDimensionsMenuItem
                initialValue={menuItem.initialValue}
                onValueChange={menuItem.onValueChange}
                label={menuItem.label}
                items={menuItem.items}
              />
            </TireDimensionsItemWrapper>
          ))}
        </Flex>
        <Flex gap={30}>
          <TireDimensionsSecondItemWrapper label={t('catalog.tires.search.criteria.seasonality', 'Seasonality')}>
            <TireSeasonSelection
              season={season}
              setSeason={(newSeason) => setSeason(newSeason)}
              menuOpen={menuOpen}
              setMenuOpen={(isOpen) => setMenuOpen(isOpen)}
              showValue
              bordered
            />
          </TireDimensionsSecondItemWrapper>
          <TireDimensionsSecondItemWrapper label={t('catalog.tires.search.criteria.brands', 'Brands')}>
            <TireBrandSelection brands={brands} setBrands={setBrands} bordered />
          </TireDimensionsSecondItemWrapper>
          <TireDimensionsSecondItemWrapper label={t('catalog.tires.search.criteria.sets', 'Sets')}>
            <TireSetSelection />
          </TireDimensionsSecondItemWrapper>
        </Flex>
        <MarginBox mb={30} />
        <BlackButton
          stretch
          onClick={handleSearch}
          disabled={
            !tireSearchInputDimensions?.width ||
            !tireSearchInputDimensions?.series ||
            !tireSearchInputDimensions?.diameter
          }
        >
          {t('catalog.tires.search', 'Search')}
        </BlackButton>
      </Flex>
    </MarginBox>
  );
};
