import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from 'app/AppStore';
import { getEstimateTotalPrice, getValidRowTablesCount } from 'domains/estimate/Estimate.store';
import { ClientView, getCurrency } from 'domains/user';
import { Divider, Flex, LightCard, Text } from 'UI';
import { textFormatter } from 'utils/format';

export interface TotalSectionProps {
  estimateId: string;
  sparePartsView?: string;
}

const TotalSection = ({ estimateId, sparePartsView }: TotalSectionProps) => {
  const { t } = useTranslation();
  const numberOfItems = useSelector((state: RootState) => getValidRowTablesCount(state, estimateId));
  const priceDetail = useSelector((state: RootState) => getEstimateTotalPrice(state, estimateId));
  const currency = useSelector(getCurrency);
  return (
    <LightCard
      title={<Trans i18nKey="estimate.pdf.estimate_total_items">Estimate total: {{ numberOfItems }} items</Trans>}
    >
      <Flex>
        <Text type={'section'}>{`${t('common.price.total_VAT_excl', 'Total VAT. Excl')}:`}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(priceDetail.priceVatExcluded, currency)}</Text>
      </Flex>
      <Flex>
        <Text type={'section'}>{t('common.price.vat', 'VAT:')}</Text>
        <Flex />
        <Text type={'section'}>{textFormatter.formatCurrency(priceDetail.vat, currency)}</Text>
      </Flex>
      {sparePartsView === ClientView && Number(priceDetail.discount) > 0 && (
        <Flex>
          <Text type={'section'}>{`${t('common.discount', 'Discount')}:`}</Text>
          <Flex />
          <Text type={'section'}>{textFormatter.formatCurrency(priceDetail.discount, currency)}</Text>
        </Flex>
      )}
      <Divider />
      <Flex>
        <Text type={'price_tag'}>{`${t('common.price.total_vat_incl', 'Total VAT. Incl')}:`}</Text>
        <Flex />
        <Text type={'price_tag'}>{textFormatter.formatCurrency(priceDetail.priceVatIncluded, currency)}</Text>
      </Flex>
    </LightCard>
  );
};

export default TotalSection;
