import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useParams } from 'react-router-dom';
/* eslint-disable max-len */
import { VehicleIAMPartCategoryTreeItem } from '@1po/1po-bff-fe-spec/generated/catalog/category/model/VehicleIAMPartCategoryTreeItem';
import { ROUTER_CATALOG_SEARCH, ROUTER_CATALOG_TIRES, ROUTER_CATALOG_VEHICLE } from 'app/AppRouter';
import { RootState } from 'app/AppStore';
import { DataContainer, ErrorWithLabel } from 'components/DataContainer';
import Loader from 'components/Loader';
import {
  useFetchIAMTechnicalDataTree,
  useFetchVehicle,
  useUpdateVehicleSearchHistory,
} from 'domains/catalog/Catalog.requests';
import {
  getExplodedIAMTree,
  getLastSearchedVehicleKey,
  getLastSearchVehicleTechnicalData,
  getSearchVehicleResult,
  resetPreviousTechnicalCriteria,
  setLastSearchedVehicleKey,
} from 'domains/catalog/Catalog.store';
import {
  ActiveTabParam,
  CAR_PARTS_TAB,
  IamCatalogTabsType,
  PARAM_LABOR_TIME,
  PARAM_SPARE_PARTS,
  PreferredTabParam,
  TECHNICAL_DATA_TAB,
  TECHNICAL_DOCUMENTATION_TAB,
  TechnicalDataIdParam,
  VehicleCountry,
} from 'domains/catalog/Catalog.types';
import { getIAMCatalogSourcePaths } from 'domains/catalog/Catalog.utils';
import { useFetchEstimateSettingsSubscription } from 'domains/estimate/Estimate.requests';
import {
  useFetchIAMAvailableModules,
  useFetchIAMMaintenancePlan,
} from 'domains/maintenancePlan/MaintenancePlan.requests';
import { useFetchIAMTires } from 'domains/tires/Tire.requests';
import { getIamCatalogTabsView, getUserRights, setIamCatalogTabsView, UserRole } from 'domains/user';
import { CatalogTabs, renderVehicleTabContentSticky } from 'pages/CatalogPage/common';
import { BrandsSwitch } from 'pages/CatalogPage/IAM/BrandsSwitch/BrandsSwitch';
import CarPartGroupsSection from 'pages/CatalogPage/IAM/CarPartGroupsSection';
import FullTextSearchResult from 'pages/CatalogPage/IAM/FullTextSearchResult';
import SubcategorySection from 'pages/CatalogPage/IAM/SubcategorySection';
import { Container, Empty, Flex, indentPadding, MarginBox, StickyBoxCustom, Tab, Tabs, TabsTitle } from 'UI';
import { ERROR, getData, hasUserAnyRight, isQueryBMM, normalizeVIN, NOT_FOUND, SearchData } from 'utils';
import { useIsScrolled } from 'utils/hooks/useIsScrolled';
import { OFFSET_SUB_HEADER, useOffsetTop } from 'utils/hooks/useOffsetTop';
import { CarCategorySection } from './CarCategorySection/CarCategorySection';
import IAMTires from './Tires';
import CatalogBreadcrumb from '../common/CatalogBreadcrumb';
import QuickAccess from '../common/QuickAccess';
import { useResetQuickAccessCategories } from '../common/QuickAccess/QuickAccess';

export const CatalogTabsIAM = ({
  tree,
  tabKey,
  setTabKey,
}: {
  tree: SearchData<VehicleIAMPartCategoryTreeItem[]> | undefined;
  tabKey: IamCatalogTabsType;
  setTabKey: (t: IamCatalogTabsType) => void;
}) => {
  const { t } = useTranslation();
  const vehicleTechnicalData = useSelector((state: RootState) => getLastSearchVehicleTechnicalData(state));
  const hasTechnicalData = vehicleTechnicalData && vehicleTechnicalData.length > 0;
  const userRights = getData(useSelector(getUserRights));
  //eslint-disable-next-line
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const activeTab = params.get(ActiveTabParam);
  const history = useHistory();

  useEffect(() => {
    if (tabKey == TECHNICAL_DATA_TAB) {
      return;
    }
    if (activeTab === PARAM_SPARE_PARTS || activeTab === PARAM_LABOR_TIME) {
      setTabKey(CAR_PARTS_TAB);
    }
  }, [activeTab, setTabKey, tabKey]);

  const tabSwitcher = (tabType: IamCatalogTabsType) => {
    if (tabType === TECHNICAL_DOCUMENTATION_TAB) {
      return;
    }

    if (tabType === CAR_PARTS_TAB) {
      params.set(ActiveTabParam, PARAM_SPARE_PARTS);
      params.set(PreferredTabParam, PARAM_SPARE_PARTS);
      history.replace(`${location.pathname}?${params}`);
    }
    setTabKey(tabType);
  };

  useEffect(() => {
    if (
      (!hasTechnicalData ||
        !hasUserAnyRight(
          [UserRole.IAM_FAST_ACCESS, UserRole.IAM_PRO_ACCESS, UserRole.IAM_PRO_PLUS_ACCESS],
          userRights,
        )) &&
      tabKey === 'technical_data'
    ) {
      tabSwitcher('car_parts');
      history.push('/');
    }
    // eslint-disable-next-line
  }, [hasTechnicalData, userRights]);

  return (
    <Tabs activeKey={tabKey} onChange={(tab: IamCatalogTabsType) => tabSwitcher(tab)}>
      <Tab tab={<TabsTitle>{t('catalog.parts.category.car_parts', 'Car parts')}</TabsTitle>} key={'car_parts'}>
        <MarginBox my={15} ml={20}>
          <DataContainer data={tree?.searchStatus} ErrorState={() => <></>}>
            <CatalogBreadcrumb tree={tree?.data} />
          </DataContainer>
        </MarginBox>
        <DataContainer
          data={tree?.searchStatus}
          ErrorState={() => (
            <ErrorWithLabel
              label={t('catalog.parts.backend_error', 'Car parts are temporarily unavailable, please try again later.')}
            />
          )}
        >
          <Switch>
            <Route path={getIAMCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}`)} exact>
              <BrandsSwitch />
              <CarPartGroupsSection />
            </Route>
            <Route
              path={getIAMCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:subcategoryId?`)}
              exact
            >
              <BrandsSwitch />
              <CarCategorySection />
            </Route>
            <Route
              path={getIAMCatalogSourcePaths(
                `/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId/:categoryId/:subcategoryId`,
              )}
              exact
            >
              <SubcategorySection />
            </Route>
            <Route path={'*'}>
              <Redirect to={'/'} />
            </Route>
          </Switch>
        </DataContainer>
      </Tab>
    </Tabs>
  );
};
const IAMCatalogPage = ({ setCatalogShowMenu }: { setCatalogShowMenu: (v: boolean) => void }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { query } = useParams<{
    query: string;
  }>();
  const tabKey = useSelector(getIamCatalogTabsView);
  const isScrolled = useIsScrolled();
  const isBMM = isQueryBMM(query ?? '');
  const result = useSelector(
    (state: RootState) =>
      getSearchVehicleResult(state, query) ??
      getSearchVehicleResult(state, query && normalizeVIN(query).substring(0, 17)),
  );
  const searchStatus = result?.searchVehicleStatus;
  const offsetTop = useOffsetTop('OTHER_BRANDS_CATALOG');
  const lastSearchedVehicleKey = useSelector(getLastSearchedVehicleKey);
  const params = new URLSearchParams(location.search);
  const tree = getData(useSelector((state: RootState) => getExplodedIAMTree(state, lastSearchedVehicleKey)));
  const { t } = useTranslation();

  const setTabKey = (tab: IamCatalogTabsType) => {
    dispatch(setIamCatalogTabsView(tab));
  };

  useEffect(() => {
    if (
      !lastSearchedVehicleKey ||
      (lastSearchedVehicleKey !== query && lastSearchedVehicleKey && !lastSearchedVehicleKey.includes(query))
    ) {
      dispatch(resetPreviousTechnicalCriteria());
      dispatch(setLastSearchedVehicleKey(query));
    }
  }, [dispatch, query, lastSearchedVehicleKey]);

  useEffect(() => {
    if (lastSearchedVehicleKey !== query && lastSearchedVehicleKey && lastSearchedVehicleKey.includes(query)) {
      const replacedUrl = history.location.pathname.replace(query, lastSearchedVehicleKey);
      history.replace(replacedUrl);
    }
  }, [history, query, lastSearchedVehicleKey]);

  useEffect(() => {
    if (tabKey !== TECHNICAL_DATA_TAB) {
      params.delete(TechnicalDataIdParam);
      history.replace(`${location.pathname}?${params}`);
    }
    // eslint-disable-next-line
  }, [tabKey]);

  useEffect(() => {
    const country = result?.vehicleDetail?.country;
    if (params.get(VehicleCountry) !== country && country) {
      params.set(VehicleCountry, country);
      history.replace(`${location.pathname}?${params}`);
    }
  });

  useFetchEstimateSettingsSubscription();
  useFetchVehicle(query, searchStatus);
  useUpdateVehicleSearchHistory(result?.vehicleDetail);
  useFetchIAMAvailableModules(result?.vehicleDetail);
  useFetchIAMTechnicalDataTree(result?.vehicleDetail);
  useFetchIAMMaintenancePlan(result?.vehicleDetail);
  useFetchIAMTires(result?.vehicleDetail?.iamVehicle?.versionCode);
  useResetQuickAccessCategories();

  if (!result || !result.vehicleDetail) {
    switch (searchStatus) {
      case NOT_FOUND:
        return (
          <MarginBox my={220}>
            <Empty />
          </MarginBox>
        );
      case ERROR:
        return (
          <MarginBox my={220}>
            <ErrorWithLabel
              label={t(
                'catalog.parts.search.by_vin_or_vrn.error',
                'Sorry, we could not find any vehicle associated, please try again later.',
              )}
            />
          </MarginBox>
        );
      default:
        return <Loader />;
    }
  }

  return (
    <Switch>
      <Route path={getIAMCatalogSourcePaths(`/:query${ROUTER_CATALOG_VEHICLE}${ROUTER_CATALOG_SEARCH}/:label`)}>
        <Container alignText={'left'}>
          <FullTextSearchResult />
        </Container>
      </Route>
      <Route path={getIAMCatalogSourcePaths(`/:query${ROUTER_CATALOG_TIRES}`)}>
        <Container alignText={'left'} padding={indentPadding}>
          <StickyBoxCustom offsetTop={offsetTop + OFFSET_SUB_HEADER} zIndex={898}>
            <CatalogTabs
              result={result.vehicleDetail}
              vehicleImage={result?.vehicleDetail?.imageUrl}
              query={query}
              setCatalogShowMenu={setCatalogShowMenu}
            />
            {renderVehicleTabContentSticky(
              offsetTop,
              result.vehicleDetail,
              result?.vehicleDetail?.imageUrl,
              isBMM,
              query,
              isScrolled,
            )}
          </StickyBoxCustom>
          <QuickAccess />
          <IAMTires />
        </Container>
      </Route>
      <Route
        path={getIAMCatalogSourcePaths(
          `/:query${ROUTER_CATALOG_VEHICLE}/:carPartGroupId?/:categoryId?/:subcategoryId?/:plateId?`,
        )}
      >
        <Container alignText={'left'} padding={indentPadding}>
          <StickyBoxCustom offsetTop={offsetTop} zIndex={898}>
            <CatalogTabs
              result={result.vehicleDetail}
              vehicleImage={result?.vehicleDetail?.imageUrl}
              query={query}
              setCatalogShowMenu={setCatalogShowMenu}
            />
          </StickyBoxCustom>
          <QuickAccess />
          <CatalogTabsIAM tree={tree} tabKey={tabKey} setTabKey={setTabKey} />
        </Container>
      </Route>
      <Route path={getIAMCatalogSourcePaths(`/:query`)}>
        <Container alignText={'left'} padding={indentPadding}>
          <Flex>
            <CatalogTabs
              result={result.vehicleDetail}
              vehicleImage={result?.vehicleDetail?.imageUrl}
              query={query}
              setCatalogShowMenu={setCatalogShowMenu}
            />
          </Flex>
        </Container>
      </Route>
      <Route path={'*'}>
        <Redirect to={'/'} />
      </Route>
    </Switch>
  );
};

export default IAMCatalogPage;
