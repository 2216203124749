import styled from 'styled-components';
import { Flex } from 'UI/Flex';

export const ProductPreviewCard = styled(Flex)`
  border-radius: 37px;
  box-shadow: 0 5px 10px 0 ${({ theme }) => theme.color.grey75};
  padding: 16px;
  position: relative;
  overflow: hidden;
`;

export const AddToEstimatePositionWrapper = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  z-index: 890;
`;
